@font-face {
    font-family: "Gotham-Medium";
    src: local('Gotham-Medium'), url('../../../assets/fonts/HCo_Gotham_1/Gotham-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
  
.sms_offers_container{
    background-color: #01338c;
    padding: 80px 200px;
    min-height: 100vh;
    .waitingComponent{
        margin-top: 200px;
    }
    .sms_opt{
        background-color: #FFF;
        border-radius: 27px;
        padding: 30px 60px 20px;
        .language{
            text-align: right;
            select{
                border: 1px solid #707070;
                font-family: "Gotham-Medium";
                width: 65px;
                height: 33px;
                padding-left: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
                background: url(./../../../assets/img/select_lang_arrow.svg) no-repeat right;
                background-position-x: 45px;
                &:focus{
                    outline: none;
                }
            }
        }
        .logo{
            display: block;
            width: 270px;
            margin: 5px auto 60px;
        }
        .steps_container{
            h1{
                font-family: "Gotham-meduim";
                font-weight: bold;
                text-align: center;
                margin-bottom: 60px;
                font-size: 24px;
                letter-spacing: -0.72px;
                color: #2B2B2B;
            }
            p{
                font-size: 20px;
                letter-spacing: -0.6px;
                color: #2B2B2B;
                text-align: right;
                margin-bottom: 20px;
            }
            h4{
                font-weight: normal;
                font-size: 18px;
                letter-spacing: -0.54px;
                color: #000;
                margin-bottom: 20px;
            }
            form {
                & > button{
                    cursor: pointer;
                    display: block;
                    margin: 60px auto;
                    width: 400px;
                    max-width: 100%;
                    height: 60px;
                    background-color: #1782FB;
                    color: #FFF;
                    border-radius: 15px;
                    font-size: 24px;
                    font-family: "Gotham-Medium";
                    border: none;
                    outline: 0;
                    &:disabled{
                        cursor: not-allowed;
                        opacity: .5;
                    }
                }
                .options{
                    margin: 90px 0;
                    display: flex;
                    margin: auto;
                    margin-top: 90px;
                    flex-direction: row-reverse;
                    justify-content: center;
                    text-align: center;
                    .option{
                        display: inline-block;             
                        background-color: #FFF;
                        border: 3px solid #01338c;
                        color: #01338c;
                        padding: 5px 0;
                        font-size: 25px;
                        cursor: pointer;
                        border-radius: 25px;
                        margin: 0 15px;
                        width: 100px;               
                    }
                    div:hover{    
                        background-color: #01338c;
                        color: #FFF;
                    }
                    .active{      
                        background-color: #01338c;
                        color: #FFF;               
                    }
                }
            }
        }
        .social_links{
            margin: 80px auto 30px;
            text-align: center;
            a{
                color: #01338D;
                margin: 10px;
                cursor: pointer;
            }
        }
        .copy_right{
            text-align: center;
            font-size: 12px;
            letter-spacing: -0.36px;
            color: #01338D;
        }
        .confirmation_message{
            text-align: center;
            padding: 80px 0 0;
            p{
                font-size: 25px;
                color: #2B2B2B;
                font-weight: bold;
                margin-bottom: 100px;
            }
            button{
                cursor: pointer;
                display: block;
                margin: 60px auto;
                width: 400px;
                max-width: 100%;
                height: 60px;
                background-color: #1782FB;
                color: #FFF;
                border-radius: 15px;
                font-size: 24px;
                font-family: "Gotham-Medium";
                border: none;
                outline: 0;
                &:disabled{
                    cursor: not-allowed;
                    opacity: .5;
                }
            }
            span{
                vertical-align: middle;
                color: #2B2B2B;
                font-size: 24px;
                font-family: "Gotham-Medium";
            }
            img{
                width: 180px;
                margin-left: 20px;
            }
        }
    }
}

// Mobile 
@media (max-width:991px) {
    .sms_offers_container{
        padding: 80px 40px;
    }
}
// Mobile 
@media (max-width:767px) {
    .sms_offers_container{
        padding: 20px;
        .sms_opt{
            border-radius: 27px;
            padding: 20px;
            .logo{
                width: 180px;
            }
            .steps_container{
                h1{
                    font-size: 20px;
                }
                p, span{
                    font-size: 18px;
                }
                h4{
                    font-size: 16px;
                }
                form {
                    .options{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .option{             
                            margin: 20px;   
                            width: 200px;      
                        }
                    }
                }
                button{
                    height: 38px;
                    border-radius: 10px;
                    font-size: 20px;
                }
            }
            .confirmation_message{
                
                p{
                    font-size: 25px;
                    margin-bottom: 100px;
                }
                >span{
                    font-size: 20px;
                }
                img{
                    width: 150px;
                    margin-left: 10px;
                }
            }
        }
    }
    
}