.waitingComponent{
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
}
.loginWaitingDiv{
    margin: auto;
}
.ctMtMC{
    margin: auto;
}
.waitingContent{
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(-50%,-50%);
    
}