.progressbar_accept{
    background-color: #DBDEE2;
    width: 100%;
    height: 7px;
    border-radius: 15px;
    overflow: hidden;
}
.progressbar_accept span{
    display: block;
    background-color: #0080FD;
    border-radius: 15px;
    height: 100%;
    transition: all ease-in-out .5s;
}