// Fonts
@font-face {
    font-family: "Gotham-Light";
    src: url('/../assets/fonts/HCo_Gotham_1/Gotham-Light.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "Gotham-Medium";
    src: local('Gotham-Medium'), url('../../../assets/fonts/HCo_Gotham_1/Gotham-Medium.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
//mixins
//media mixin
@mixin m($width) {
    @media(max-width:#{$width}px) {
        @content;
    }
  }
// Lang mixin
@mixin en {
    html[dir="ltr"] & {
        @content;
    }
}
@mixin ar {
    html[dir="rtl"] & {
        @content;
    }
}
// style
// Onboarding steps modal
.onboarding_container{
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    .left_side{
        background-color: #F9FAFB;
        padding: 140px 0 0;
        @media (max-width:767px) {
            padding: 20px;
        }
        @include en{
            text-align: left;
        }
        @include ar{
            text-align: right;
        }
        .steps_container{
            max-width: 200px;
            margin: auto;
            font-size: 12px;
            p{
                position: relative;
                line-height: 52px;
                margin: 0;
                color: #344054;
                opacity: 0.4;
                &.active{
                    opacity: 1;
                }
                span{
                    display: inline-block;
                    background-color: #FAFAFA;
                    padding: 10px 0;
                    width: 32px;
                    height: 32px;
                    line-height: 12px;
                    text-align: center;
                    border: 1px solid #344054;
                    border-radius: 50%;
                    @include en{
                        margin-right: 10px;
                    }
                    @include ar{
                        margin-left: 10px;
                    }
                    &.passed{
                        background-color: #039855;
                        border: 1px solid #039855;
                        img{
                            vertical-align: unset;
                        }
                    }
                }
            }
            .dashed_line{
                min-height: 30px;
                opacity: 0.4;
                @include en{
                    margin: 0 0 5px 15px;
                    border-left: 1px dashed #344054;
                }
                @include ar{
                    margin: 0 15px 5px 0;
                    border-right: 1px dashed #344054;
                }
                &.active{
                    opacity: 1;
                }
            }
            .active_step{
                color: #2D4B6833;
            }
        }
    }
    .right_side{
        overflow: hidden;
        position: relative;
        background-color: #FFF;
        padding: 90px 60px 90px 60px;
        @media (max-width:767px) {
            padding: 20px 30px 120px;
        }
        @include en{
            text-align: left;
        }
        @include ar{
            text-align: right;
        }
        h3{
            color: #2D4B68;
            font-size: 18px;
            margin-bottom: 20px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
            img{
                width: 10px;
                height: 10px;
                margin-top: 5px;
                cursor: pointer;
                @include en{
                    float: right;
                }
                @include ar{
                    float: left;
                }
            }
        }
        .steps_mob_container{
            margin-bottom: 15px;
            span{
                display: inline-block;
                width: calc(20% - 2px);
                height: 2px;
                background-color: #D0D5DD;
                &.passed{
                    background-color: #1782FB;
                }
                &.active{
                    background-color: #667085;
                }
                &:not(:last-child){
                    @include en{
                        margin-right: 2px;
                    }
                    @include ar{
                        margin-left: 2px;
                    }
                }
            }
        }
        .info_container{
            position: relative;
            h2{
                font-weight: 600;
                font-size: 16px;
                color: #093464;
                @media (max-width: 576px) {
                    font-size: 14px;
                }
                span{
                    font-weight: 400;
                    font-size: 12px;
                    color: #98A2B3;
                    @media (max-width: 576px) {
                        font-size: 10px;
                    }
                    @include en{
                        float: right;
                    }
                    @include ar{
                        float: left;
                    }
                    &.required{
                        float: none !important;
                        font-size: 18px;
                        line-height: 18px;
                        color: #ED0006;
                    }
                }
            }
            h3{
                font-weight: 500;
                font-size: 14px;
                color: #344054;
                margin: 30px 0 8px;
            }
            > span{
                color: #73879C;
                font-size: 14px;
            }
            .side_note{
                position: relative;
                padding: 15px;
                font-weight: 400;
                font-size: 14px;
                color: #667085;
                margin: 50px 0 0;
                @media (max-width:767px) {
                    padding: 10px 0;
                    margin: 20px auto 0;
                }
                @media (max-width:767px) {
                    padding: 0;
                    margin: 0;
                }
            }
            .terms_container{
                height: 500px;
                overflow-x: hidden;
                overflow-y: scroll;
                padding: 55px 40px;
                margin: 30px auto;
                box-shadow: 0px 4px 8px #2C27380A;
                border: 1px solid #EEF0F4;
                border-radius: 8px;
                h3{
                    color: #2D4B68;
                    font-size: 16px;
                    font-weight: bold;
                }
                h4{
                    color: #2D4B68;
                    font-size: 14px;
                    font-weight: bold;
                }
                p{
                    color: #2D4B68;
                    font-size: 14px;
                }
                li{
                    color: #2D4B68;
                    font-size: 14px;
                }
                // Custom Scrollbar
                /* width */
                &::-webkit-scrollbar {
                    width: 7px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #DFE3E8;
                    border-radius: 8px;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #2D4B689D;
                    border-radius: 8px;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }
        }
    }
}
.onboarding_container > div.row{
    min-height: 100vh;
}
.onboarding_form{
    margin: 40px 0;
    &.form_waiting{
        position: relative;
        i{
            position: absolute;
            top: 45px;
            color: #1782FB;
            @include en{
                right: -35px;
            }
            @include ar{
                left: -35px;
            }
            @media (max-width: 767px){
                @include en{
                    right: -30px;
                }
                @include ar{
                    left: -30px;
                }
            }
        }
    }
    label{
        display: block;
        font-weight: 500;
        font-size: 14px;
        color: #475467;
        span{
            font-size: 13px;
            color: #73879C;
            @include en{
                margin-left: 5px;
            }
            @include ar{
                margin-right: 5px;
            }
            &.optional{
                @include en{
                    margin-left: 0;
                    float: right;
                }
                @include ar{
                    margin-right: 0;
                    float: left;
                }
            }
        }
        span.required{
            font-size: 18px;
            line-height: 18px;
            color: #ED0006;
        }
    }
    >span{
        display: block;
        color: #73879C;
        font-size: 14px;
        margin-bottom: 10px;
    }
    input{
        font-size: 16px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #EEF0F4;
        border-radius: 5px;
        color: #2D4B68 !important;
        width: 100%;
        max-width: 100%;
        height: 50px;
        outline: none;
        @include en{
            padding-left: 10px;
        }
        @include ar{
            padding-right: 10px;
        }
        &:hover{
            border: 1px solid #EEF0F4;
        }
        &:focus{
            border: 1px solid #74B4FD;
            outline: none;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E6FE;
        }
        &::placeholder{
            color: #BDC1C6;
        }
        &:disabled{
            cursor: not-allowed;
            background-color: #F2F2F2;
        }
    }
    .radio_input_label{
        display: block;
        height: 50px;
        padding: 15px 20px;
        margin-bottom: 20px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        cursor: pointer;
        &.active{
            background: #E8F3FF;
            border: 1px solid #1782FB;
        }
        input{
            display: inline-block;
            width: 20px;
            height: 20px;
            outline: none;
            box-shadow: none;
        }
        span{
            vertical-align: top;
            color: #101828;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        @media (max-width: 767px) {
            &:last-of-type{
                margin-bottom: 8px;
            }
        }
    }
    .choose_label{
        display: inline-block;
        width: 100%;
        min-height: 50px;
        padding: 10px;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        cursor: pointer;
        &.active{
            color: #1782FB;
            background: #E8F3FF;
            border: 1px solid #1782FB;
        }
        @media (max-width:767px) {
            padding: 10px 1px;
        }
    }
    .checkbox_input_label{
        display: block;
        height: 50px;
        padding: 15px 20px;
        margin-bottom: 10px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        cursor: pointer;
        &.active{
            background: #E8F3FF;
            border: 1px solid #1782FB;
        }
        input{
            display: inline-block;
            width: 16px;
            height: 16px;
            outline: none;
            box-shadow: none;
            cursor: pointer;
            vertical-align: text-bottom;
            @include ar{
                vertical-align: sub;
            }
        }
        span{
            vertical-align: top;
            color: #101828;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        &.confirm_terms{
            border: none;
            padding: 0;
            margin: 0;
        }
    }
    .sub_checkbox_div{
        @include en{
            padding-left: 45px;
        }
        @include ar{
            padding-right: 45px;
        }
        label{
            display: block;
            height: 20px;
            margin-bottom: 20px;
            &.active{
                background: #E8F3FF;
                border: 1px solid #1782FB;
            }
            input{
                display: inline-block;
                width: 16px;
                height: 16px;
                outline: none;
                box-shadow: none;
                cursor: pointer;
                vertical-align: text-bottom;
                @include ar{
                    vertical-align: sub;
                }
            }
            span{
                vertical-align: top;
                color: #101828;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .alert{
        margin-top: 10px;
        min-height: 52px;
        line-height: 26px;
        font-size: 16px;
    }
    .note{
        font-weight: 400;
        font-size: 14px;
        color: #667085;
        margin: 8px 0 0;
    }
    // phone number package 
    .phoneNumberWrapper {
        direction: ltr;
        position: relative;
        box-shadow: 0px 4px 8px #2C27380A;
        border: 1px solid #EEF0F4;
        border-radius: 5px;
        box-sizing: border-box;
        color: #2D4B68;
        padding: 0 0 0 10px;
        // react-phone-number-input
        .PhoneInput{
            height: 50px;
            select{
                display: block;
            }
            .PhoneInputCountry{
                margin: 0;
                .PhoneInputCountryIcon{
                    box-shadow: none;
                    background: none;
                    margin: 0 10px 0 0;
                }
            }
            .PhoneInputInput{
                border: none !important;
                padding: 0 10px !important;
                box-shadow: none !important;
                outline: none;
                margin: 0 0 0 5px !important;
            }
        }
    }
    // react-select library
    .react-select__control{
        height: 50px;
        border: 1px solid #EEF0F4;
        border-radius: 5px;
        &:hover{
            border: 1px solid #EEF0F4;
        }
    }
    .react-select__control--is-focused{
        border: 1px solid #74B4FD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E6FE;
        &:hover{
            border: 1px solid #1782FB;
        }
    }
    .react-select__indicator-separator{
        display: none;
    }
    .react-select__indicators{
        top: 50%;
        height: 50px;
    }
    .react-select__placeholder{
        color: #BDC1C6;
    }
    .react-select__option{
        background-color: #FFF;
        color: #101828;
        &:hover, &:active{
            background-color: #E8F3FF;
            color: #1782FB;
        }
    }
    .react-select__option--is-selected{
        background-color: #E8F3FF;
        color: #1782FB;
    }
    .react-select__single-value{
        top: 50%;
        font-size: 16px;
        color: #2D4B68;
    }
    .css-1g6gooi{
        padding: 0;
        margin: 0;
        height: 50px;
    }
    .react-select__input{
        input{
            margin: 0;
            padding: 0;
            color: #2D4B68;
            line-height: 50px;
            padding: 0;
            &:focus{
                box-shadow: none;
            }
        }
        
    }
    .react-select__value-container{
        height: 50px;
    }
}
.onboarding_form_btns{
    margin: 50px auto 20px;
    @include en{
        text-align: right;
    }
    @include ar{
        text-align: left;
    }
    @media (max-width:767px) {
        text-align: center !important;
    }
    button{
        font-size: 16px;
        padding: 10px 30px;
        border: 1px solid #1782FB;
        border-radius: 8px;
        outline: none;
        cursor: pointer;
        &:focus{
            outline: none;
        }
        &:disabled{
            opacity: .5;
            cursor: not-allowed;
        }
        @media (max-width:767px) {
            width: 48%;
            padding: 10px 0;
        }

    }
    .onboarding_close{
        color: #1782FB;
        background: none;
    }
    .onboarding_submit{
        min-width: 220px;
        color: #FFF;
        background: #1782FB 0% 0% no-repeat padding-box;
        box-shadow: 0px 4px 8px #2C273814;
        @include en{
            margin-left: 20px;
        }
        @include ar{
            margin-right: 20px;
        }
        @media (max-width:767px) {
            min-width: 0;
            @include en{
                margin-left: 2%;
            }
            @include ar{
                margin-right: 4%;
            }
            &.mob_w_100{
                width: 100%;
                margin: auto !important;
            }
        }
    }
}
.onboarding_note{
    font-size: 16px;
    color: #1782FB;
    background: #E6F0F9 0% 0% no-repeat padding-box;
    border: 1px solid #B2CCE9;
    border-radius: 5px;
    padding: 15px;
    margin: 20px 0;
    p{
        margin: 0;
        i{
            line-height: 16px;
            @include en{
                margin: 5px 10px 0 0;
            }
            @include ar{
                margin: 5px 0 0 10px;
            }
        }
    }
}
.onboarding_upload_image{
    position: relative;
    min-height: 150px;
    input{
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        cursor: pointer;
    }
    label{
        display: block;
        padding: 15px 5px;
        border: 1px solid #D0D5DD;
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        img{
            width: 41px;
            height: 40px;
            margin: 15px auto;
        }
        p{
            font-weight: 500;
            font-size: 14px;
            color: #1782FB;
            margin-bottom: 5px;
            span{
                font-weight: 400;
                font-size: 14px;
                color: #101828;
            }
        }
        >span{
            font-weight: 400;
            font-size: 12px;
            color: #667085;
        }
    }
    .displayed_img{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        div{
            position: relative;
            width: fit-content;
            height: 100%;
            border: 1px solid #D0D5DD;
            border-radius: 8px;
            span{
                position: absolute;
                top: 5px;
                padding: 0 9px 2px;
                background: #F9FAFB;
                border: 1px solid #D0D5DD;
                border-radius: 50%;
                opacity: 0.8;
                cursor: pointer;
                @include en{
                    right: 5px;
                }
                @include ar{
                    left: 5px;
                }
            }
            img{
                height: 100%;
                max-width: 100%;
                border-radius: 8px;
            }
        }
    }
}
// Modal container
[tabindex='-1'] {
    z-index: 9999 !important;
}
// Onboardong Modal
.onboarding_modal{
    margin: 30vh auto !important;
    @media (max-width: 576px) {
        width: calc(100% - 30px) !important;
        margin: 35vh auto !important;
    }
    .modal-content{
        border-radius: 8px;
        padding: 25px;
        margin: auto !important;
    }
    .modal-body{
        padding: 0 !important;
    }
    h3{
        font-weight: 500;
        font-size: 16px;
        color: #093464;
        padding: 10px 0 15px;
        border-bottom: 0.5px solid #D0D5DD;
        img{
            margin-top: 5px;
            cursor: pointer;
            @include en{
                float: right;
            }
            @include ar{
                float: left;
            }
        }
    }
    p{
        font-weight: 400;
        font-size: 14px;
        color: #344054;
        margin: 20px 0 30px;
    }
    .buttons_container{
        @include en{
            text-align: right;
        }
        @include ar{
            text-align: left;
        }
        button{
            padding: 10px 15px;
            border: none;
            color: #667085;
            background: none;
            cursor: pointer;
            outline: none;
            &.submit{
                color: #FFF;
                background: #1782FB;
                border-radius: 8px;
                margin-left: 10px;
            }
        }
    }
}
.onboarding_error_page{
    padding: 200px 0 0;
    @media (max-width: 767px) {
        padding: 10px 0 0;
    }
}
.pdf_file_container{
    text-align: center;
    background-color: #E8F3FF;
    padding: 10px;
    border: 1px solid #1782FB;
    border-radius: 5px;
    margin: 20px auto;
    p{
        margin-bottom: 5px;
        color: #1782FB;
    }
    a{
        color: #1782FB;
        &:hover{
            text-decoration: none;
        }
        i{
            margin: 0 5px;
        }
    }
}
.loading_message{
    color: #1782FB;
    margin-bottom: 10px;
    @media (max-width: 767px) {
        font-size: 14px;
    }
}
// Responsive
.onboarding_web{
    display: block;
    @media (max-width: 767px) {
        display: none;
    }
}
.onboarding_mob{
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
}