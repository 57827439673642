@font-face {
  font-family: "Gotham-Light";
  src: url("/../assets/fonts/HCo_Gotham_1/Gotham-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Gotham-Medium";
  src: local("Gotham-Medium"), url("../../../assets/fonts/HCo_Gotham_1/Gotham-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal; }

.onboarding_container {
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-x: hidden; }
  .onboarding_container .left_side {
    background-color: #F9FAFB;
    padding: 140px 0 0; }
    @media (max-width: 767px) {
      .onboarding_container .left_side {
        padding: 20px; } }
    html[dir="ltr"] .onboarding_container .left_side {
      text-align: left; }
    html[dir="rtl"] .onboarding_container .left_side {
      text-align: right; }
    .onboarding_container .left_side .steps_container {
      max-width: 200px;
      margin: auto;
      font-size: 12px; }
      .onboarding_container .left_side .steps_container p {
        position: relative;
        line-height: 52px;
        margin: 0;
        color: #344054;
        opacity: 0.4; }
        .onboarding_container .left_side .steps_container p.active {
          opacity: 1; }
        .onboarding_container .left_side .steps_container p span {
          display: inline-block;
          background-color: #FAFAFA;
          padding: 10px 0;
          width: 32px;
          height: 32px;
          line-height: 12px;
          text-align: center;
          border: 1px solid #344054;
          border-radius: 50%; }
          html[dir="ltr"] .onboarding_container .left_side .steps_container p span {
            margin-right: 10px; }
          html[dir="rtl"] .onboarding_container .left_side .steps_container p span {
            margin-left: 10px; }
          .onboarding_container .left_side .steps_container p span.passed {
            background-color: #039855;
            border: 1px solid #039855; }
            .onboarding_container .left_side .steps_container p span.passed img {
              vertical-align: unset; }
      .onboarding_container .left_side .steps_container .dashed_line {
        min-height: 30px;
        opacity: 0.4; }
        html[dir="ltr"] .onboarding_container .left_side .steps_container .dashed_line {
          margin: 0 0 5px 15px;
          border-left: 1px dashed #344054; }
        html[dir="rtl"] .onboarding_container .left_side .steps_container .dashed_line {
          margin: 0 15px 5px 0;
          border-right: 1px dashed #344054; }
        .onboarding_container .left_side .steps_container .dashed_line.active {
          opacity: 1; }
      .onboarding_container .left_side .steps_container .active_step {
        color: #2D4B6833; }
  .onboarding_container .right_side {
    overflow: hidden;
    position: relative;
    background-color: #FFF;
    padding: 90px 60px 90px 60px; }
    @media (max-width: 767px) {
      .onboarding_container .right_side {
        padding: 20px 30px 120px; } }
    html[dir="ltr"] .onboarding_container .right_side {
      text-align: left; }
    html[dir="rtl"] .onboarding_container .right_side {
      text-align: right; }
    .onboarding_container .right_side h3 {
      color: #2D4B68;
      font-size: 18px;
      margin-bottom: 20px; }
      @media (max-width: 767px) {
        .onboarding_container .right_side h3 {
          margin-bottom: 10px; } }
      .onboarding_container .right_side h3 img {
        width: 10px;
        height: 10px;
        margin-top: 5px;
        cursor: pointer; }
        html[dir="ltr"] .onboarding_container .right_side h3 img {
          float: right; }
        html[dir="rtl"] .onboarding_container .right_side h3 img {
          float: left; }
    .onboarding_container .right_side .steps_mob_container {
      margin-bottom: 15px; }
      .onboarding_container .right_side .steps_mob_container span {
        display: inline-block;
        width: calc(20% - 2px);
        height: 2px;
        background-color: #D0D5DD; }
        .onboarding_container .right_side .steps_mob_container span.passed {
          background-color: #1782FB; }
        .onboarding_container .right_side .steps_mob_container span.active {
          background-color: #667085; }
        html[dir="ltr"] .onboarding_container .right_side .steps_mob_container span:not(:last-child) {
          margin-right: 2px; }
        html[dir="rtl"] .onboarding_container .right_side .steps_mob_container span:not(:last-child) {
          margin-left: 2px; }
    .onboarding_container .right_side .info_container {
      position: relative; }
      .onboarding_container .right_side .info_container h2 {
        font-weight: 600;
        font-size: 16px;
        color: #093464; }
        @media (max-width: 576px) {
          .onboarding_container .right_side .info_container h2 {
            font-size: 14px; } }
        .onboarding_container .right_side .info_container h2 span {
          font-weight: 400;
          font-size: 12px;
          color: #98A2B3; }
          @media (max-width: 576px) {
            .onboarding_container .right_side .info_container h2 span {
              font-size: 10px; } }
          html[dir="ltr"] .onboarding_container .right_side .info_container h2 span {
            float: right; }
          html[dir="rtl"] .onboarding_container .right_side .info_container h2 span {
            float: left; }
          .onboarding_container .right_side .info_container h2 span.required {
            float: none !important;
            font-size: 18px;
            line-height: 18px;
            color: #ED0006; }
      .onboarding_container .right_side .info_container h3 {
        font-weight: 500;
        font-size: 14px;
        color: #344054;
        margin: 30px 0 8px; }
      .onboarding_container .right_side .info_container > span {
        color: #73879C;
        font-size: 14px; }
      .onboarding_container .right_side .info_container .side_note {
        position: relative;
        padding: 15px;
        font-weight: 400;
        font-size: 14px;
        color: #667085;
        margin: 50px 0 0; }
        @media (max-width: 767px) {
          .onboarding_container .right_side .info_container .side_note {
            padding: 10px 0;
            margin: 20px auto 0; } }
        @media (max-width: 767px) {
          .onboarding_container .right_side .info_container .side_note {
            padding: 0;
            margin: 0; } }
      .onboarding_container .right_side .info_container .terms_container {
        height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 55px 40px;
        margin: 30px auto;
        box-shadow: 0px 4px 8px #2C27380A;
        border: 1px solid #EEF0F4;
        border-radius: 8px;
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        .onboarding_container .right_side .info_container .terms_container h3 {
          color: #2D4B68;
          font-size: 16px;
          font-weight: bold; }
        .onboarding_container .right_side .info_container .terms_container h4 {
          color: #2D4B68;
          font-size: 14px;
          font-weight: bold; }
        .onboarding_container .right_side .info_container .terms_container p {
          color: #2D4B68;
          font-size: 14px; }
        .onboarding_container .right_side .info_container .terms_container li {
          color: #2D4B68;
          font-size: 14px; }
        .onboarding_container .right_side .info_container .terms_container::-webkit-scrollbar {
          width: 7px; }
        .onboarding_container .right_side .info_container .terms_container::-webkit-scrollbar-track {
          background: #DFE3E8;
          border-radius: 8px; }
        .onboarding_container .right_side .info_container .terms_container::-webkit-scrollbar-thumb {
          background: #2D4B689D;
          border-radius: 8px; }
        .onboarding_container .right_side .info_container .terms_container::-webkit-scrollbar-thumb:hover {
          background: #555; }

.onboarding_container > div.row {
  min-height: 100vh; }

.onboarding_form {
  margin: 40px 0; }
  .onboarding_form.form_waiting {
    position: relative; }
    .onboarding_form.form_waiting i {
      position: absolute;
      top: 45px;
      color: #1782FB; }
      html[dir="ltr"] .onboarding_form.form_waiting i {
        right: -35px; }
      html[dir="rtl"] .onboarding_form.form_waiting i {
        left: -35px; }
      @media (max-width: 767px) {
        html[dir="ltr"] .onboarding_form.form_waiting i {
          right: -30px; }
        html[dir="rtl"] .onboarding_form.form_waiting i {
          left: -30px; } }
  .onboarding_form label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: #475467; }
    .onboarding_form label span {
      font-size: 13px;
      color: #73879C; }
      html[dir="ltr"] .onboarding_form label span {
        margin-left: 5px; }
      html[dir="rtl"] .onboarding_form label span {
        margin-right: 5px; }
      html[dir="ltr"] .onboarding_form label span.optional {
        margin-left: 0;
        float: right; }
      html[dir="rtl"] .onboarding_form label span.optional {
        margin-right: 0;
        float: left; }
    .onboarding_form label span.required {
      font-size: 18px;
      line-height: 18px;
      color: #ED0006; }
  .onboarding_form > span {
    display: block;
    color: #73879C;
    font-size: 14px;
    margin-bottom: 10px; }
  .onboarding_form input {
    font-size: 16px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EEF0F4;
    border-radius: 5px;
    color: #2D4B68 !important;
    width: 100%;
    max-width: 100%;
    height: 50px;
    outline: none; }
    html[dir="ltr"] .onboarding_form input {
      padding-left: 10px; }
    html[dir="rtl"] .onboarding_form input {
      padding-right: 10px; }
    .onboarding_form input:hover {
      border: 1px solid #EEF0F4; }
    .onboarding_form input:focus {
      border: 1px solid #74B4FD;
      outline: none;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E6FE; }
    .onboarding_form input::placeholder {
      color: #BDC1C6; }
    .onboarding_form input:disabled {
      cursor: not-allowed;
      background-color: #F2F2F2; }
  .onboarding_form .radio_input_label {
    display: block;
    height: 50px;
    padding: 15px 20px;
    margin-bottom: 20px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer; }
    .onboarding_form .radio_input_label.active {
      background: #E8F3FF;
      border: 1px solid #1782FB; }
    .onboarding_form .radio_input_label input {
      display: inline-block;
      width: 20px;
      height: 20px;
      outline: none;
      box-shadow: none; }
    .onboarding_form .radio_input_label span {
      vertical-align: top;
      color: #101828;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px; }
    @media (max-width: 767px) {
      .onboarding_form .radio_input_label:last-of-type {
        margin-bottom: 8px; } }
  .onboarding_form .choose_label {
    display: inline-block;
    width: 100%;
    min-height: 50px;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer; }
    .onboarding_form .choose_label.active {
      color: #1782FB;
      background: #E8F3FF;
      border: 1px solid #1782FB; }
    @media (max-width: 767px) {
      .onboarding_form .choose_label {
        padding: 10px 1px; } }
  .onboarding_form .checkbox_input_label {
    display: block;
    height: 50px;
    padding: 15px 20px;
    margin-bottom: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    cursor: pointer; }
    .onboarding_form .checkbox_input_label.active {
      background: #E8F3FF;
      border: 1px solid #1782FB; }
    .onboarding_form .checkbox_input_label input {
      display: inline-block;
      width: 16px;
      height: 16px;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      vertical-align: text-bottom; }
      html[dir="rtl"] .onboarding_form .checkbox_input_label input {
        vertical-align: sub; }
    .onboarding_form .checkbox_input_label span {
      vertical-align: top;
      color: #101828;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px; }
    .onboarding_form .checkbox_input_label.confirm_terms {
      border: none;
      padding: 0;
      margin: 0; }
  html[dir="ltr"] .onboarding_form .sub_checkbox_div {
    padding-left: 45px; }
  html[dir="rtl"] .onboarding_form .sub_checkbox_div {
    padding-right: 45px; }
  .onboarding_form .sub_checkbox_div label {
    display: block;
    height: 20px;
    margin-bottom: 20px; }
    .onboarding_form .sub_checkbox_div label.active {
      background: #E8F3FF;
      border: 1px solid #1782FB; }
    .onboarding_form .sub_checkbox_div label input {
      display: inline-block;
      width: 16px;
      height: 16px;
      outline: none;
      box-shadow: none;
      cursor: pointer;
      vertical-align: text-bottom; }
      html[dir="rtl"] .onboarding_form .sub_checkbox_div label input {
        vertical-align: sub; }
    .onboarding_form .sub_checkbox_div label span {
      vertical-align: top;
      color: #101828;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px; }
  .onboarding_form .alert {
    margin-top: 10px;
    min-height: 52px;
    line-height: 26px;
    font-size: 16px; }
  .onboarding_form .note {
    font-weight: 400;
    font-size: 14px;
    color: #667085;
    margin: 8px 0 0; }
  .onboarding_form .phoneNumberWrapper {
    direction: ltr;
    position: relative;
    box-shadow: 0px 4px 8px #2C27380A;
    border: 1px solid #EEF0F4;
    border-radius: 5px;
    box-sizing: border-box;
    color: #2D4B68;
    padding: 0 0 0 10px; }
    .onboarding_form .phoneNumberWrapper .PhoneInput {
      height: 50px; }
      .onboarding_form .phoneNumberWrapper .PhoneInput select {
        display: block; }
      .onboarding_form .phoneNumberWrapper .PhoneInput .PhoneInputCountry {
        margin: 0; }
        .onboarding_form .phoneNumberWrapper .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
          box-shadow: none;
          background: none;
          margin: 0 10px 0 0; }
      .onboarding_form .phoneNumberWrapper .PhoneInput .PhoneInputInput {
        border: none !important;
        padding: 0 10px !important;
        box-shadow: none !important;
        outline: none;
        margin: 0 0 0 5px !important; }
  .onboarding_form .react-select__control {
    height: 50px;
    border: 1px solid #EEF0F4;
    border-radius: 5px; }
    .onboarding_form .react-select__control:hover {
      border: 1px solid #EEF0F4; }
  .onboarding_form .react-select__control--is-focused {
    border: 1px solid #74B4FD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #D1E6FE; }
    .onboarding_form .react-select__control--is-focused:hover {
      border: 1px solid #1782FB; }
  .onboarding_form .react-select__indicator-separator {
    display: none; }
  .onboarding_form .react-select__indicators {
    top: 50%;
    height: 50px; }
  .onboarding_form .react-select__placeholder {
    color: #BDC1C6; }
  .onboarding_form .react-select__option {
    background-color: #FFF;
    color: #101828; }
    .onboarding_form .react-select__option:hover, .onboarding_form .react-select__option:active {
      background-color: #E8F3FF;
      color: #1782FB; }
  .onboarding_form .react-select__option--is-selected {
    background-color: #E8F3FF;
    color: #1782FB; }
  .onboarding_form .react-select__single-value {
    top: 50%;
    font-size: 16px;
    color: #2D4B68; }
  .onboarding_form .css-1g6gooi {
    padding: 0;
    margin: 0;
    height: 50px; }
  .onboarding_form .react-select__input input {
    margin: 0;
    padding: 0;
    color: #2D4B68;
    line-height: 50px;
    padding: 0; }
    .onboarding_form .react-select__input input:focus {
      box-shadow: none; }
  .onboarding_form .react-select__value-container {
    height: 50px; }

.onboarding_form_btns {
  margin: 50px auto 20px; }
  html[dir="ltr"] .onboarding_form_btns {
    text-align: right; }
  html[dir="rtl"] .onboarding_form_btns {
    text-align: left; }
  @media (max-width: 767px) {
    .onboarding_form_btns {
      text-align: center !important; } }
  .onboarding_form_btns button {
    font-size: 16px;
    padding: 10px 30px;
    border: 1px solid #1782FB;
    border-radius: 8px;
    outline: none;
    cursor: pointer; }
    .onboarding_form_btns button:focus {
      outline: none; }
    .onboarding_form_btns button:disabled {
      opacity: .5;
      cursor: not-allowed; }
    @media (max-width: 767px) {
      .onboarding_form_btns button {
        width: 48%;
        padding: 10px 0; } }
  .onboarding_form_btns .onboarding_close {
    color: #1782FB;
    background: none; }
  .onboarding_form_btns .onboarding_submit {
    min-width: 220px;
    color: #FFF;
    background: #1782FB 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #2C273814; }
    html[dir="ltr"] .onboarding_form_btns .onboarding_submit {
      margin-left: 20px; }
    html[dir="rtl"] .onboarding_form_btns .onboarding_submit {
      margin-right: 20px; }
    @media (max-width: 767px) {
      .onboarding_form_btns .onboarding_submit {
        min-width: 0; }
        html[dir="ltr"] .onboarding_form_btns .onboarding_submit {
          margin-left: 2%; }
        html[dir="rtl"] .onboarding_form_btns .onboarding_submit {
          margin-right: 4%; }
        .onboarding_form_btns .onboarding_submit.mob_w_100 {
          width: 100%;
          margin: auto !important; } }

.onboarding_note {
  font-size: 16px;
  color: #1782FB;
  background: #E6F0F9 0% 0% no-repeat padding-box;
  border: 1px solid #B2CCE9;
  border-radius: 5px;
  padding: 15px;
  margin: 20px 0; }
  .onboarding_note p {
    margin: 0; }
    .onboarding_note p i {
      line-height: 16px; }
      html[dir="ltr"] .onboarding_note p i {
        margin: 5px 10px 0 0; }
      html[dir="rtl"] .onboarding_note p i {
        margin: 5px 0 0 10px; }

.onboarding_upload_image {
  position: relative;
  min-height: 150px; }
  .onboarding_upload_image input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer; }
  .onboarding_upload_image label {
    display: block;
    padding: 15px 5px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    text-align: center;
    cursor: pointer; }
    .onboarding_upload_image label img {
      width: 41px;
      height: 40px;
      margin: 15px auto; }
    .onboarding_upload_image label p {
      font-weight: 500;
      font-size: 14px;
      color: #1782FB;
      margin-bottom: 5px; }
      .onboarding_upload_image label p span {
        font-weight: 400;
        font-size: 14px;
        color: #101828; }
    .onboarding_upload_image label > span {
      font-weight: 400;
      font-size: 12px;
      color: #667085; }
  .onboarding_upload_image .displayed_img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px; }
    .onboarding_upload_image .displayed_img div {
      position: relative;
      width: fit-content;
      height: 100%;
      border: 1px solid #D0D5DD;
      border-radius: 8px; }
      .onboarding_upload_image .displayed_img div span {
        position: absolute;
        top: 5px;
        padding: 0 9px 2px;
        background: #F9FAFB;
        border: 1px solid #D0D5DD;
        border-radius: 50%;
        opacity: 0.8;
        cursor: pointer; }
        html[dir="ltr"] .onboarding_upload_image .displayed_img div span {
          right: 5px; }
        html[dir="rtl"] .onboarding_upload_image .displayed_img div span {
          left: 5px; }
      .onboarding_upload_image .displayed_img div img {
        height: 100%;
        max-width: 100%;
        border-radius: 8px; }

[tabindex='-1'] {
  z-index: 9999 !important; }

.onboarding_modal {
  margin: 30vh auto !important; }
  @media (max-width: 576px) {
    .onboarding_modal {
      width: calc(100% - 30px) !important;
      margin: 35vh auto !important; } }
  .onboarding_modal .modal-content {
    border-radius: 8px;
    padding: 25px;
    margin: auto !important; }
  .onboarding_modal .modal-body {
    padding: 0 !important; }
  .onboarding_modal h3 {
    font-weight: 500;
    font-size: 16px;
    color: #093464;
    padding: 10px 0 15px;
    border-bottom: 0.5px solid #D0D5DD; }
    .onboarding_modal h3 img {
      margin-top: 5px;
      cursor: pointer; }
      html[dir="ltr"] .onboarding_modal h3 img {
        float: right; }
      html[dir="rtl"] .onboarding_modal h3 img {
        float: left; }
  .onboarding_modal p {
    font-weight: 400;
    font-size: 14px;
    color: #344054;
    margin: 20px 0 30px; }
  html[dir="ltr"] .onboarding_modal .buttons_container {
    text-align: right; }
  html[dir="rtl"] .onboarding_modal .buttons_container {
    text-align: left; }
  .onboarding_modal .buttons_container button {
    padding: 10px 15px;
    border: none;
    color: #667085;
    background: none;
    cursor: pointer;
    outline: none; }
    .onboarding_modal .buttons_container button.submit {
      color: #FFF;
      background: #1782FB;
      border-radius: 8px;
      margin-left: 10px; }

.onboarding_error_page {
  padding: 200px 0 0; }
  @media (max-width: 767px) {
    .onboarding_error_page {
      padding: 10px 0 0; } }

.pdf_file_container {
  text-align: center;
  background-color: #E8F3FF;
  padding: 10px;
  border: 1px solid #1782FB;
  border-radius: 5px;
  margin: 20px auto; }
  .pdf_file_container p {
    margin-bottom: 5px;
    color: #1782FB; }
  .pdf_file_container a {
    color: #1782FB; }
    .pdf_file_container a:hover {
      text-decoration: none; }
    .pdf_file_container a i {
      margin: 0 5px; }

.loading_message {
  color: #1782FB;
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .loading_message {
      font-size: 14px; } }

.onboarding_web {
  display: block; }
  @media (max-width: 767px) {
    .onboarding_web {
      display: none; } }

.onboarding_mob {
  display: none; }
  @media (max-width: 767px) {
    .onboarding_mob {
      display: block; } }
