@import url(//fonts.googleapis.com/earlyaccess/droidarabickufi.css);

// @font-face {
//   font-family: 'Segoe UI',Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif ;
//   font-style: normal;
//   font-weight: 400;
//   src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot);
//   src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.eot?#iefix) format('embedded-opentype'),
//     url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff2) format('woff2'),
//     url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.woff) format('woff'),
//     url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Regular.ttf) format('truetype');
// }
@font-face {
  font-family: "Gotham-Light";
  src: url('/../assets/fonts/HCo_Gotham_1/Gotham-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url('/../assets/fonts/HCo_Gotham_1/Gotham-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Droid Arabic Kufi', serif;;
  font-style: normal;
  font-weight: 700;
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot);
  src: url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.eot?#iefix) format('embedded-opentype'),
    url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.woff) format('woff'),
    url(//fonts.gstatic.com/ea/droidarabickufi/v6/DroidKufi-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe'), url(/assets/fonts/Segoe.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe UI Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Italic'), url(/src/assets/fonts/Segoe/UI/Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SegoeBold'), url(/assets/fonts/SegoeBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Segoe UI Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Segoe UI Bold Italic'), url(/src/assets/fonts/Segoe/UI/Bold/Italic.ttf) format('truetype');
}


//mixins
//media mixin
@mixin m($width) {
  @media(max-width:#{$width}px) {
    @content;
  }
}

// Lang mixin
@mixin en {
  html[dir="ltr"] & {
    @content;
  }
}

@mixin ar {
  html[dir="rtl"] & {
    @content;
  }
}

// how to use mixin
// @include en {
//     font-family: Sans;
// }
// @include ar {
//     font-family: cairo;
// }

$Font_Ar : Droid Arabic Kufi;
$Font_Ar_Bold :Droid Arabic Kufi;

body {
  color: #73879C !important;

  @include ar {
    font-family: $Font_Ar;
  }
}
.full_width{
  width: 100%;
}
// Here you can add other styles
.text-center {
  text-align: center;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c7c7c7;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #c7c7c7;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #c7c7c7;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #c7c7c7;
}

.centerHeight {
  line-height: 30px;
}
.app .navbar{
  padding: 0rem;
}
.app-header {
  display: block !important;
  background-color: #EDEDED;
  border-bottom: 1px solid #D9DEE4;
  height: 57px;
  position: fixed;
  padding-left: 11%;
}
.navbar-nav{
  display: inline-block;
  @include en{
    float: right;
  }
  @include ar{
    float: left;
  }
}
.app-header .nav-item{
  display: inline-block;
}
.btn {
  padding: 4px 15px;
  border-radius: 0;
  font-weight: bold;
}

.sidebar-fixed .sidebar,
.sidebar .nav,
.sidebar .sidebar-nav {
  width: 230px;

  @include m(991) {
    width: 70px;
  }
}

.sidebar {
  @include en {
    margin-left: -230px;

    @include m(992) {
      margin-left: 0;
    }
  }

  @include ar {
    margin-right: -230px;

    @include m(992) {
      margin-right: 0;
    }
  }
}
// .navbar{
//   padding: 0rem;
// }
.app-header .navbar-brand{
    width: 230px;
    background: #2A3F54;
    height: 57px;
}
.navbar-brand{
    margin: 0!important;
    text-align: center;
}
.navbar-brand .navbar-brand-full{
    // width: calc(100% - 60px);
    height: auto;
    cursor: pointer;
}
.nav-title{
    position: relative;
    height: 53px;
}
.nav-title span{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    padding: 17px 15px;
}

.sidebar-minimizer {
  @include m(992) {
    display: inline-block !important;
  }
}

.app-header .navbar-brand {
  width: 230px;
  background: #2A3F54;
  height: 57px;
}

.navbar-brand {
  margin: 0 !important;

}

.sidebar-minimized .app-header {
  @include en {
    padding-left: 80px !important;

    @include m(991) {
      padding-left: 10px !important;
    }
  }

  @include ar {
    padding-right: 80px !important;

    @include m(991) {
      padding-right: 10px !important;
    }
  }
}

@include m(992) {
  .app-header .navbar-brand {
    width: 150px;
  }
}

.sidebar-lg-show.sidebar-fixed .main,
.sidebar-lg-show.sidebar-fixed .app-footer,
.sidebar-show.sidebar-fixed .main,
.sidebar-show.sidebar-fixed .app-footer {
  @include en {
    margin-left: 230px;

    @include m(991) {
      margin-left: 70px;
    }
  }

  @include ar {
    margin-right: 230px;

    @include m(991) {
      margin-right: 70px;
    }
  }
}

.sidebar-minimized.sidebar-lg-show.sidebar-fixed .main,
.sidebar-minimized.sidebar-lg-show.sidebar-fixed .app-footer,
.sidebar-minimized.sidebar-show.sidebar-fixed .main,
.sidebar-minimized.sidebar-show.sidebar-fixed .app-footer {
  @include en {
    margin-left: 70px;

    @include m(991) {
      margin-left: 0px;
    }
  }

  @include ar {
    margin-right: 70px;

    @include m(991) {
      margin-right: 0px;
    }
  }
}

.sidebar {
  background: #2A3F54;
  font-size: 16px;

  @include ar {
    font-family: $Font_Ar_Bold;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;

  }
}

.rdr-Day .is-selected {
  background: rgba(60, 144, 231, 0.82);
}

.sidebar-lg-show.sidebar-fixed .main {
  padding-top: 65px;
}

.sidebar .nav-link:hover,
.sidebar .nav-link.active {
  background-color: rgba(255, 255, 255, .05);

  @include en {
    border-right: 5px solid gray;
  }

  @include ar {
    border-left: 5px solid gray;
  }
}

.sidebar .nav-title {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: 1px 1px #000;
  background-color: #172D44;
  padding: 18px 15px;

  @include en {
    padding-left: 15px;
  }

  @include ar {
    padding-right: 15px;
  }

  @include m(991) {
    display: none;
  }
}

.sidebar .nav-link {
  margin-bottom: 4px;
  color: #bbb;
  font-size: 13px;

  @include m(991) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    text-align: center;
    font-size: 10px;

  }
}

.sidebar .nav-link .nav-icon {
  width: 26px;
  opacity: .99;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.side_profile {
  margin-top: 20px !important;
}

.header_drop {
  font-weight: 500;
  line-height: 32px;
  color: #515356 !important;
  padding: 13px 15px 12px !important;
}

.app-header .header_drop_out .dropdown-item {
  min-width: auto;
}

.sidebar-minimized .header_drop {
  font-weight: 500 !important;
  line-height: 32px !important;
  color: #515356 !important;
  padding: 13px 15px 12px !important;
  font-size: 16px !important;
}

.sidebar-minimized .nav-item.header_drop_out {
  width: auto !important;
}

.header_drop:hover,
.header_drop:focus {
  background: #D9DEE4 !important;
}

body {
  background: #ffffff !important;
}

.navbar-nav {
  padding: 0;


  .merchant_id {
    font-weight: bold;
    margin: 0.5rem 0.7rem;
    padding: .3em .92857143em;
    background:rgba(115, 135, 156,0.5);
    border-radius: 10rem;
    color: #fff;
    span {
      padding-left: 0.4rem;
      font-size: 1em;
      
    }
  }
}

.app-header .navbar-nav .dropdown-menu-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translate3d(0, 0, 0) !important;
  top: 55px !important;

  @include en {
    right: 0 !important;
    left: auto !important;
  }

  @include ar {
    left: 0 !important;
    right: auto !important;
  }
}

.app-header .navbar-nav .nav-item:first-child .dropdown-menu-right {
  @include en {
    @include m(400) {
      left: 0 !important;
    }
  }

  @include ar {
    @include m(400) {
      right: 0 !important;
    }
  }
}

.app-header .dropdown-item {
  color: #5A738E !important;

}

.dropdown-item i {
  color: #5A738E !important;
}

.dropdown-menu a:hover { text-decoration: none; cursor: pointer; }

.dropdown-item.logout { border-top: 1px solid #c8ced3}

.header_drop_out 
.flag-icon {
  font-size: 20px;
}
.lang-flag{
  text-align: center;
}

.up_country {
  font-size: 20px;
}

.copy_rights {
  margin: 0;
  padding: 0;

  @include en {
    margin-left: auto;
  }

  @include ar {
    margin-right: auto;
  }

  @include m(991) {
    text-align: center;
    margin: auto;
  }
}

.app-footer {
  background: #fff;
  padding: 15px 20px;
  border: 0;
}

.brand-minimized .app-header .navbar-brand {
  background-color: #172D44;
}

.scrollbar-container {
  @include ar {
    direction: ltr;

    .nav {
      direction: rtl;
    }
  }

}

//Side Nav
.Welcome_Side {
  padding: 25px 15px 10px;

  p {
    font-size: 13px;
    line-height: 30px;
    color: #BAB8B8;
    margin: 0;
  }
span a{
  text-decoration: underline;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  &:hover{
    border-bottom: 2px solid #f1f1f1;
    text-decoration: none;
  }
}
  @include m(991) {
    display: none;
  }

  .merchant_id {
    margin-top: 0.6rem;
    color: #BAB8B8;
  }

}

.Header_Toggle {
  height: 57px;
  border: 0;
  background-color: transparent;
  font-size: 26px;
  color: #5A738E;
  cursor: pointer;
  margin: 0 10px;
  outline: none !important;
  float: left;
}

.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  -webkit-transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
  -webkit-transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s !important;
  transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s !important;
}

.header_logo img:first-child {
  padding: 0px;

  @include en {
    margin-right: auto;
  }

  @include ar {
    margin-left: auto;
  }

  @include m(991) {
    display: none;
  }
}

.brand-minimized .header_logo img:first-child {
  display: none;
}

.brand-minimized .Welcome_Side {
  display: none;
}

.brand-minimized .header_logo img:last-child {
  display: block;

  @include m(991) {
    display: none;
  }

}

.header_logo img:last-child {
  display: none;
  margin: auto;

  @include m(991) {
    display: block;
  }
}

.sidebar-minimized .sidebar .nav,
.sidebar-minimized .sidebar .nav-item,
.sidebar-minimized .scrollbar-container,
.sidebar-minimized .sidebar,
.sidebar-minimized .sidebar .nav-link:hover {
  width: 70px !important;

  @include m(991) {
    display: none;
  }
}

.sidebar-minimized .nav-item a {
  font-size: 10px;
}

.header-fixed .app-header {
  @include en {
    right: 0;
  }

  @include ar {
    left: 0;
  }
}

.sidebar-fixed .app-header+.app-body .sidebar {
  height: 100% !important;
  @media (max-width: 767px) {
    position: fixed;
    left: 0px;
    top: 0px;
    @include ar{
      left: auto;
      right: 0px;
    }
  }
}

.header-fixed .app-body {
  margin: 0;
}

.sidebar-fixed .app-header+.app-body .sidebar {
  z-index: 1050;
}

.header-fixed .app-header {
  @include en {
    padding-left: 230px;

    @include m(991) {
      padding-left: 30px;
    }
  }

  @include ar {
    padding-right: 230px;

    @include m(991) {
      padding-right: 80px;
    }
  }

}

.brand-minimized .header-fixed .app-header {
  @include en {
    padding-left: 50px;
  }

  @include ar {
    padding-right: 50px;
  }
}

.sidebar-minimized .scrollbar-container {
  .nav-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 15px 0;
    margin: 0;
    white-space: normal !important;
    text-align: center;

    i {
      font-size: 25px !important;
      margin-bottom: 2px;
    }

  }

  .sidebar .nav-link .nav-icon {
    margin: 0 !important;
  }

  &.ps-container {
    .nav-link {
      display: block;
      flex-direction: row;
      padding: 0.75rem 1rem;

    }
  }
}

.sidebar .nav-link .nav-icon {
  margin: 0 !important;
  color: #bbb;
  font-size: 17px;
  margin-bottom: 4px !important;

  @include en {
    margin-right: 5px !important;

    @include m(991) {
      margin-right: 0px !important;
    }
  }

  @include ar {
    margin-left: 5px !important;

    @include m(991) {
      margin-left: 0px !important;
    }
  }
}

.sidebar-minimized .scrollbar-container {
  overflow: hidden !important;
}

.loginWaitingDiv {
  width: 100px;
}

/*------------------------------------------------------------------------------------*/
/*--------------------- portal styles ------------------------------------------------*/
.sub_Table {
  border: none;
}

.sub_Table {
    @include ar {
     .numbers-direction {
       direction: ltr !important;
     }
   }
}

.sub_Table>tbody>* {
  border: none;
}

.sub_Table>tbody>tr>* {
  border: none;
  padding: 4px 2px;
}

.sub_Table>tbody>tr:hover {
  transition: none;
  transform: none;
  background-color: #fff;
  box-shadow: none;
  color: #73879C;
}

.sub_Table>tbody>tr>td {
  vertical-align: middle;
  position: relative;
  overflow-wrap: break-word;

  @media (max-width:767px) {
    display: block;
    width: 100%;
  }
}

.sub_Table>tbody>tr>td>span.link {
  word-wrap: break-word;
  white-space: normal;
}

.sub_Table>tbody>tr>td:first-of-type {
  width: 1px !important;
  white-space: nowrap;
  font-weight: bold;
}
.sub_Table>tbody>tr>td a{
  overflow-wrap: break-word;
}
.sub_Table.left_border>tbody>tr>td:first-of-type {
  @include en {
    padding-left: 10px;
  }

  @include ar {
    padding-right: 10px;
  }
}

.sub_Table>tbody>tr>td:last-of-type {
  padding: 4px 14px;
  max-width: 490px;
  text-wrap: wrap;

  @media (max-width:767px) {
    max-width: 200px;
  }
}
.ps__rail-y:hover > .ps__thumb-y, .ps__rail-y:focus > .ps__thumb-y, .ps__rail-y.ps--clicking{
  background-color: transparent !important;
}
.ps__thumb-y{
  width: 5px;
}
.sub_Table>tbody>tr>td:last-of-type::before {
  content: " : ";
  font-weight: bold;
  position: absolute;

  @include en {
    left: 0px;
  }

  @include ar {
    right: 0px;
  }

  top: 50%;
  transform: translateY(-50%);

  @media (max-width:767px) {
    content: "";
  }
}
.users_x_content .sub_Table>tbody>tr>td:last-of-type::before {
  content: "";
}
.left_border {
  @media (min-width:767px) {
    @include en {
      border-left: 1px solid #c8ced3;
    }

    @include ar {
      border-right: 1px solid #c8ced3;
    }
  }
}

.full_loading {
  background-color: #fff;
  height: 100vh;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999999;
  opacity: 1;
}
.users_x_content .sub_Table>tbody>tr>td:last-of-type::before {
  content: "";
}

.sideMenuFooter {
  display: block;
  padding: 5px 0 0;
  width: 100%;
  background: #2A3F54;

  @include m(991) {
    display: none;
  }
}

.brand-minimized .sideMenuFooter {
  display: none;
}

.sideMenuFooter>div {
  padding: 8px 0px;
  padding-bottom: 8px;
  text-align: center;
  width: 25%;
  font-size: 17px;
  display: block;
  float: left;
  background: #172D44;
  color: #5A738E;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.sideMenuFooter>div:hover {
  background: #425567;
  color: #fff;
}

.sideMenuFooter>div>i {
  transition: all 0.3s ease-in-out;
}

.sideMenuFooter>div:hover>i {
  color: #fff;
  transform: scale(1.2);
}

select:hover:disabled {
  cursor: not-allowed;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.main {
  position: relative;
  padding-top: 70px;
}

.mainErrorDiv {
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100vh - 50px);
  right: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 3;
}

.mainErrorDiv>* {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-width {
  width: 100% !important;
}

.full-width-input {
  width: 100% !important;
  margin: 0px !important;
}

.tableSpan-xs {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  word-wrap: break-word;
}

.tableSpan-sm {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  word-wrap: break-word;
}

.tableSpan-md {
  display: inline-block;
  max-width: 255px;
  overflow: hidden;
  word-wrap: break-word;
}

.inputGroup>div {
  position: relative;
}

.inputGroup .simple-alert {
  color: #813838;
  font-size: 16px;
}

.inputGroup .simple-alert>i {
  display: inline-block;
  position: absolute;
  font-size: 20px;
  top: 36px;
  right: 23px;

  @include ar {
    right: auto;
    left: 23px;
  }
}

.inputGroup .simple-alert.outDiv {
  position: static;
}

.inputGroup .simple-alert.outDiv>i {
  right: 25px;

  @include ar {
    right: auto;
    left: 25px;
  }
}

.inputGroup .simple-alert>.alert-danger {
  padding: 5px 15px;
  margin: 0px;
  margin-top: 5px;
}

.btn {
  font-weight: normal;
}

.accept-input {
    display: inline-block;
    width: auto;
    height: 33px;
    background-color: #fff;
    border: 2px solid #E6E9ED;
    border-radius: 0px;
    padding: 0.2rem 0.4rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0px 2px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 5px;
    &:focus{
      border-color: #E6E9ED;
      outline: none;
      box-shadow: none;
    }
}

.acceptTextarea {
  height: auto;
}

.date-input {
  max-width: 100px;
  padding: 4px 10px;
  text-align: center;
  &:hover{
    cursor: pointer;
  }
}
.full-date-input {
  width: 15rem;
  padding: 4px 10px;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
.next_btn{
  display: inline-block;
  color: #fff;
  font-family: "Gotham-Medium";
  font-size: 18px;
  border: none;
  background-color: #0091D9;
  box-shadow: 0px 0px 6px #00000029;
  height: 45px;
  line-height: 45px;
  width: 250px;
  max-width: 100% !important;
  text-align: center;
  outline: none !important;
  cursor: pointer;
  &:disabled{
    cursor: not-allowed;
    opacity: .5;
  }
  &:hover{
    text-decoration: none;
    color: #fff;
  }
}
.next_error_div{
  float: left;
  width: 100%;
  text-align: center;
  padding: 30px 0px;
}
.accept-btn {
  display: inline-block;
    width: auto;
    background-color: #73879C;
    border: 1px solid #73879C;
    border-radius: 2px !important;
    padding: 0.5rem 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0px 2px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
    vertical-align: middle;
    font-size: 1em;
    font-weight: 700;
    i {
      @include en {
        margin-right: 0;
      }

      @include ar {
        margin-left: 0;
      }
    }
      &:hover{
        background-color: rgba(53, 73, 92,0.9);
        border: 1px solid rgba(53, 73, 92,0.9);
        color: #fff;
        cursor: pointer;
        text-decoration: none;
      }
      &:focus{
        box-shadow: none;
        outline: none;
      }
      &:disabled{
        cursor: not-allowed;
      }
}
.filtersBtn,.accept_submit{
  display: inline-block;
  width: auto;
  background-color: rgba(12, 127, 181, 0.8);
  border: 1px solid rgba(12, 127, 181, 0.8);
  border-radius: 0px;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px 2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  vertical-align: middle;
  font-size: 1em;
  font-weight: 700;
  border-radius: 1.5rem;
    &:hover{
      background-color: rgba(12, 127, 181, 0.9);
      border: 1px solid rgba(12, 127, 181, 0.9);
      color: #fff;
      cursor: pointer;
      text-decoration: none;
    }
    &:focus{
      box-shadow: none;
      outline: none;
    }
}
.invoice-select{
  display: inline-block;
  width: auto;
  background-color: #fff;
  border: 2px solid #E6E9ED;
  border-radius: 5px;
  padding: 0rem 1rem;
  height: 51px;

}
.accept-select {
  display: inline-block;
  background-color: #fff;
padding: 0.4rem 0rem !important;
text-align: center;
width: auto;
height: calc(2.25rem + 2px);
font-size: 1rem;
line-height: 1.5;
color: #495057;
background-clip: padding-box;
border: 1px solid #ced4da;
border-radius: .25rem;
 -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
option{
  // padding: 0.3rem !important;
  // margin: 1rem;
  border-radius: 5px;
}
  @include ar {
    padding: 1px 15px;
  }
  &:hover{
    background-color: #E6E9ED;
    color: #000;
    cursor: pointer;
  }
 &:focus{
  border-color: #E6E9ED;
  outline: none;
  box-shadow: none;
 } 
}
.showDataBtn{
  border-radius: 10px;
    padding:0.5rem;
    float: right;
    margin-right: 1rem;
    cursor: pointer;
    @include ar{
      float: left;
      margin-left: 1rem;
      margin-right: 0rem;
    }
}
.activeBtn{
background: #28a745;
color: #f1f1f1;
}
.testBtn{
background: #293F52;
color: #f1f1f1;
}
.activeMode{
font-weight: bolder;
}
//home graphs
.home-page {
  position: relative;
  .chart-wrapper canvas {
    height: 400px;
  }
}
.transfer-element{
  position: relative;
  overflow: hidden;
  padding: 1rem;
  width: 25%;
  background-color: #293F53;
  color: #fff;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 10px;
  @media (max-width:767px) {
    width: 100%;
    text-align: center;
    float: left;
  }
&:last-of-type:before{
height: 0px;
}
&:nth-child(5n+0):before{
height: 0px;
}
p{
  margin-top: 2rem;
  text-align: center;
  font-size: 1.1em;
  font-weight: bold;
}
h3{
 text-align: center;
 margin-top: 0.5rem;
}
}
  @media (max-width:767px){
  .integ-head{
  text-align: center;
  margin-bottom: 25px;
  .integ-h{
  font-size: 16px;
  margin-bottom: 15px;
  display: block;
  }
}
  .integ-body {
 .data-element,.tranx-element{
  flex-basis: 100%;
  text-align: center;
  float: left;
 &:before{
  right: 0px;
  }
  p{
    text-align: center !important;
  }
  }
}
.showDataBtn{
  float: left;
  padding: 0.4rem;
}
  }
  .orders-page{
    .integ-body{
      display: -webkit-flex; /* Safari */
      -webkit-flex-wrap: wrap; /* Safari 6.1+ */
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .data-element{
        position: relative;
        overflow: hidden;
        padding: 1rem;
        width: 24%;
        background-color: #293F53;
        color: #fff;
        margin-left: 0.5rem;
        margin-bottom: 2rem;
        margin-top: 0.5rem;
        border-radius: 10px;
      &:last-of-type:before{
      height: 0px;
      }
      &:nth-child(5n+0):before{
      height: 0px;
      }
      }
    }
    }
      @media (max-width:767px){
      .integ-head{
      text-align: center;
      margin-bottom: 25px;
      .integ-h{
      font-size: 16px;
      margin-bottom: 15px;
      display: block;
      }
    }
      .integ-body {
     .data-element{
      flex-basis: 100%;
      text-align: center;
      float: left;
     &:before{
      right: 0px;
      }
      }
    }
  }
  .transactions-page,.orders-page,.products-page,.transactionsBs-page{
    h2{
      width: 50%;
      display: inline-block;
      margin-bottom: 1rem;
    }
   .TableDiv{
    float: right;
    margin-bottom: 1rem;
    margin-top: 1rem;
    @include ar {
      float: left;
      text-align: left;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    .filtersModalDiv{
      display: inline-block;
      width: auto;
    }
  }

}
.transfer-page{
 .transfer-header{
  width: 50%;
      display: inline-block;
      margin-bottom: 1rem;
 }
      .TableDiv{
        float: right;
        margin-bottom: 1rem;
      }
}
//export 
.exportForm label {
  float: left;

  @include ar {
    float: right;
  }
}

.exportStateNew {
  display: inline-block;
  user-select: none;
  font-weight: bold;
  color: rgba(12, 127, 181, 0.8);
  border: 1px solid rgba(12, 127, 181, 0.8);
  padding: 4px 15px;
  border-radius: 5px;
}
.stateNewBtn{
  display: inline-block;
  width: auto;
  background-color: rgba(12, 127, 181, 0.8);
  border: 1px solid rgba(12, 127, 181, 0.8);
  border-radius: 0px;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px 2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  vertical-align: middle;
  font-size: 1em;
  font-weight: 700;
  border-radius: 1.5rem;
  cursor: pointer;
  &:hover{
    background-color: rgba(12, 127, 181, 0.5);
    border: 1px solid rgba(12, 127, 181, 0.5);
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  &:focus{
    box-shadow: none;
    outline: none;
  }
}
.exportStateDownloaded {
  display: inline-block;
  user-select: none;
  font-weight: bold;
  color: #2A3F54;
border:1px solid #2A3F54;
  padding: 4px 15px;
  border-radius: 5px;
  opacity: 0.9;
}
.stateDownloadedBtn{
  display: inline-block;
  width: auto;
  background-color: #2A3F54;
  border: 1px solid #2A3F54;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0px 2px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #fff;
  vertical-align: middle;
  font-size: 1em;
  font-weight: 700;
  border-radius: 1.5rem;
  cursor: pointer;
  &:hover{
    background-color: rgba(54, 73, 93,0.9);
    border: 1px solid rgba(54, 73, 93,0.9);
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  &:focus{
    box-shadow: none;
    outline: none;
  }
}
button:disabled {
  cursor: not-allowed;
}

.currentTableDiv {
  overflow-x: auto;
  width: 100%;
  .table th,
  .table td {
    padding: 0.7rem 0.1rem;
    color: rgba(54, 73, 93, 0.7);
    text-align: center;
    font-size: 0.8em;
    border-left: 2px solid #dee2e6;
  }
  .table thead th{
    font-weight: 800;
    border-bottom: 2px solid #dee2e6 !important;
  }
}
.ordersTable{
  td {
   padding: 0.2rem !important;
   @include ar {
    .numbers-direction {
      direction: ltr !important;
    }
  }
 }
}
.exportTable{
  .table th,
  .table td {
    padding: 0.6rem;
    color: rgba(54, 73, 93, 0.7);
    text-align: center;
  }
}
table {
  border: 1px solid #c8ced3;
tbody>tr {
  transition: all 0.3s ease-in-out;

&:hover {
  background-color: transparent;
  color: #444;
  box-shadow: 0px 2px 2px #ccc, 0px -2px 2px #ccc;
  transform: translate(1px, 1px);
}
.selectedRow {
  background-color: #f0f0f0;
  cursor: pointer;
  color: #333;
  box-shadow: 0px 2px 2px #ccc, 0px -2px 2px #ccc;
  transform: translateY(-1px);
}
 tr>td,
th {
  border-left: 1px solid #c8ced3;
}
  td .original_amount {
    display: block;
    text-decoration: line-through;
    color: #000;
    font-size: 13px;
  }
}
}
.x_panel {
  direction: ltr;
  width: 100%;
  padding: 10px 17px;
  display: inline-block;
  background: #fff;
  border: 1px solid #E6E9ED;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  margin-bottom: 15px;
  transition: all .2s ease;
}
// .navbar-brand-minimized{
//   display: none !important;
// }
.x_title {
  //   border-bottom: 2px solid #E6E9ED;
  padding: 1px 5px 6px;
h2 {
  display: inline-block;
  @include m (767) {
    font-size: 16px;
    width: 100%;
    margin-bottom: 1rem;
  }
}
}
.select-Trans-Id{
  color: #172D44;
  font-weight: 800;
  padding-left: 0.5rem;
    font-size: 1.5em;
}

.x_title.custom_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.x_content {
  width: 100%;
  padding: 0 5px 6px;
  float: left;
  clear: both;
  margin-top: 5px;
}

/*header calc*/
.headerCalcCalendarDiv {
  position: relative;
}

.headerCalcCalendar,.filtersCalendar {
  direction: ltr;
  box-shadow: 10px 10px 5px 5px rgba(0, 0, 0, 0.6) ;
  padding: 5px;
  position: fixed;
  z-index: 1;
  left: 0%;
  right: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: none;
  background-color: rgba(0, 0, 0, 0.6);
  .rdr-DateRange {
    border-radius: 10px;
    // margin-top: 5rem;
    box-shadow: 10px 10px 10px 10px rgba(0,0,0,0.5);
    button.rdr-MonthAndYear-button.prev i {
      @include ar {
        transform: translate(-75%, 0);
    }
  }
  
  
    .is-selected, .is-inRange {
      background: rgba(12, 127, 181, 0.8) !important;
      color: #f1f1f1 !important; 
      font-weight: 600;
    }
  }
  .overlay{
    position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; 
  }
}
.transactionsBs-page
{
  h2{
    width: auto;
    display: inline-block;
    margin-bottom: 1rem;
    @media (max-width: 767px) {
      font-size: 18px;
    }

  }
//  .TableDiv{
//   float: right;
//   margin-bottom: 1rem;
//   margin-top: 4rem;
//   width: 100%;
//   text-align: center;
//   @include ar {
//     float: left;
//     margin-bottom: 1rem;
//   }
// }
.filtersModalDiv{
  width: 100%;
  margin: 0px;
  margin-bottom: 15px;
  @include en {
    text-align: right;
  }
  @include ar {
    text-align: left;
  }
}
.filtersModalDiv #tagsDiv{
  width: 100%;
  text-align: center;
}
}

/*table*/
table.transactionsTable tr:hover {
  cursor: pointer;
}

.paginationList {
  list-style: none;
  padding: 0px;
  float: right;

  @include ar {
    float: left;
  }
li {
  display: inline-block;

a {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  color: #555;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  outline: none;
  transition: all 0.3s ease-in-out;
  &:hover{
    color: #000;
    background-color: #E6E9ED;
    border-color: #E6E9ED;
    cursor: pointer;
  }
}
.selected>a {
  color: #000;
  background-color: #E6E9ED;
  border-color: #E6E9ED;
  cursor: pointer;
}

.disabled>a:hover {
  cursor: not-allowed;
}
}
}
.filtersModalDiv {
  display: block;
  width: 100%;
  margin: 0px;
  margin-bottom: 15px;
  @include en{
    text-align: right;
  }
  @include ar{
    text-align: left;
  }
  .dropdown-menu.show{
    @include en{
      right: 0px !important;
      left: auto !important;
    }
    @include ar{
      right: auto !important;
      left: 0px !important;
    }
  }
}
  .filter-btn{
    display: inline-block;
    width: 100% !important;
    background-color: #73879C;
    border: 2px solid #73879C;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0px 2px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #fff;
    vertical-align: middle;
    font-size: 1.1em;
    font-weight: 700;
    border-radius: 5px !important;
    &:hover{
      background-color: rgba(53, 73, 92,0.5);
      border: 2px solid rgba(53, 73, 92,0.5) !important;
      color: #fff;
      cursor: pointer;
      text-decoration: none;
    }
}
.TableDiv>select {
  float: left;
  margin-bottom: 5px;
}

html[dir="rtl"] .TableDiv>select {
  float: right;
}
.exportContainer{
  display: inline-block;
  .exportForm{
   margin: 1rem;
   .form-control{
     width: 250px;
   }
  }
}
/* start filters */
.filters-body ,.export-body{
  padding: 1.5rem 1rem;
  width: 800px;
  // right: auto ;
  // left: 2px ;
  border: 2px solid #E6E9ED;
  border-radius: 0px;
  transform: translate3d(0px, 33px, 0px) !important;
  left: -700px !important;
  box-shadow: 0px -2px 11px 1px rgba(0, 0,0,0.6); 
  background-color: #fff;
  
  @include ar {
    // left: auto !important;
    // right: 2px !important;
  }
  .form-group{
    margin: 1rem;
  }
}
.filters-body,.export-body{
 .row {
  @include ar {
    text-align: right;
  }
}

.filters-body ,.export-body
.row:last-of-type {
  margin-bottom: 0px;
}
}

.filtersSelectBtn {
  // margin-top: 15px;
  background: rgba(12, 127, 181, 0.8);
  color: #f1f1f1;
  text-transform: capitalize;
  border-style: none;
  cursor: pointer;
  outline: none;
  border: 2px solid rgba(12, 127, 181, 0.8);
  font-weight: 700;
  display: inline-block;
    width: auto;
    border-radius: 0px;
    padding: 0.5rem 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    vertical-align: middle;
    font-size: 1em;
    border-radius: 1.5rem;
    margin-top: 0.5rem;

  &:hover {
    background: rgb(12, 127, 181) !important;
    color: #f1f1f1;
    text-transform: capitalize;
    border-style: none;
    cursor: pointer;
    outline: none;
    border: 2px solid rgb(12, 127, 181);
    font-weight: 700;
  }
  i {
    @include en {
      margin-right: 0;
    }

    @include ar {
      margin-left: 0;
    }
  }
    &:focus{
      box-shadow: none;
      outline: none;
    }
    &:disabled{
      cursor: not-allowed;
    }
}

#tagsDiv {
  text-align: center;
  margin-bottom: 2rem;
}
.logo-img-2{
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 10px;
  i{
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 50%;
  height: 100%;
  opacity: 0;
  border-radius: 100%;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  color: #f1f1f1;
  font-size: 2em;
  padding-top: 10%;
  &:hover{
    opacity: 1;
  }
  }
  .merchantLogo{
    width: 50%;
    height: 50%;
    left: 50%;
    text-align: center;
    overflow: hidden;
    border-radius: 100%;
    opacity: 1;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  @include en {
    margin-right: 7px;
  }

  @include ar {
    margin-right: 0px;
    margin-left: 7px;
  }
}
#tagsDiv>div {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #E6E9ED;
  padding: 4px 15px;
  user-select: none;
  margin: 2px;
  transition: all 0.3s ease-in-out;
}

#tagsDiv>div>span {
  font-weight: bold;
}

#transDetailsAccordion .transDetailsCollapseCard table tr>td:first-of-type {
  width: 155px;
}

#transDetailsAccordion .orderDetailsCollapseCard table tr>td:first-of-type {
  width: 140px;
}

/*-- trans details --*/
.x_title .ButtonsDiv {
  padding-top: 2px;
  display: inline-block;

  @include en {
    float: right;
  }

  @include ar {
    float: left;
  }

  @include m (767) {
    display: block;
  }
  *{
    @include en {
      float: left;
    }
  
    @include ar {
      float: right;
    }
  }
  select{
    width: auto;
    display: inline-block;
  }
}

.card-body {
  padding: 1rem;
  @include ar {
    direction: rtl;
  }
}

.x_title .ButtonsDiv>* {
  vertical-align: top;
}

#transDetailsAccordion .card {
  margin-bottom: 0px;
}
.card{
  margin-bottom: 0;
  border: none;
}
.orderDetailsCollapseCard,.cartDetails,.transDetailsCollapseCard,.billingDetailsCollapseCard
,.errorDetailsCollapseCard{
  border-radius: 10px;
  margin-bottom: 0;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.card-header {
  background-color: #f1f1f1;
  border: 0px;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
}

.card-header:hover {
  cursor: pointer;
}

.card-header:first-child {
  border-radius: 10px;
}

.merchantTxnRefTable {
  margin: 0px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .filters-body,.export-body {
    width: 700px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .filters-body {
    width: 600px;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .filters-body{
    width: 532px;
    left: -26rem !important;
  }
  .export-body {
    left: -26rem !important;
    width: 532px;

  }
}

/* end filters */


// payment-page

.accept_btn {
  border-radius: 3px;
  border: 1px solid #ccc;
}

.accept_btn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.modal-header {
  padding: 8px 15px 5px 15px;
  border-bottom: 0px solid #E6E9ED;
  box-shadow: 0px 1px 4px #ccc;
  font-size: 18px;
  font-weight: 400;
  align-items: center;

  span {
    margin: 0 5px;
  }
}

.modal-content {
  border-radius: 0px;
  border: 0px solid #E6E9ED;
  width: 80%;
}

.modal-footer {
  padding: 10px 15px 10px 15px;
  border-top: 1px solid #E6E9ED;
}

.modal-header .close {
  margin: 0;
  opacity: .2;
}

.payment_edit_form .alert-danger {
  margin: 0px;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 100%;
  }
}

.addIntegTitle,
.addIframeTitle {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 13px;

  span {
    margin: 0px 5px;
    font-size: 16px;
  }
}

.shopify_option {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
  .form-check-label{
    padding-left: 0rem;
  }
}

.addIntegInput {
  margin-top: 10px;
  margin-bottom: 10px;

  input {
    width: 100%;
  }
}


/*--- start invoice page ---*/
.inputeDiv>div {
  position: relative;
}

.inputeDiv .simple-alert {
  color: #813838;
  font-size: 16px;
}

.inputeDiv .simple-alert>i {
  display: inline-block;
  position: absolute;
  font-size: 20px;
  top: 7px;
  right: 10px;

  @include ar {
    right: auto;
    left: 10px;
  }
}

.inputeDiv .simple-alert.outDiv {
  position: static;
}

.inputeDiv .simple-alert.outDiv>i {
  right: 25px;

  @include ar {
    right: auto;
    left: 25px;
  }
}

.inputeDiv .simple-alert>.alert-danger {
  padding: 5px 15px;
  margin: 0px;
  margin-top: 5px;
}

.invoiceForm label {
  width: 100%;
  text-align: left;

  @include ar {
    text-align: left;
  }

  @include m(767) {
    text-align: center;
  }
}

.invoiceForm label.switch {
  width: 40px;
}

.switch-pill .switch-slider {
  width: 40px;
  height: 26px;
}
.invoiceFormSpan {
  padding: 0rem 1rem;
  height: 33px;
  vertical-align: top;
  display: inline-block;
  min-width: 140px;
}

.invoiceFormSelectDiv {
  height: 33px;
  margin-bottom: 10px;
  display: flex;
  @include m(767) {
    height: auto;
    display: block;
  }
}

.invoiceFormSelectDiv>label {
  vertical-align: top;
  margin-top: 3px;
}

.invoiceFormSelectDiv>div {
  display: inline-block;
  vertical-align: text-bottom;
  margin: 0px 8px;

}

.invoiceFormSelectDiv>div>.accept-select {
  width: auto;
}

/*--- end invoice page ---*/
.integDetailsHead {
  margin: 10px 0;
  font-weight: 500;
  font-size: 16px;
}

.tableBold {
  font-weight: 600;
  color: #172D44;
}

.pageIntro {
  margin-top: 20px;

  h2 {
    font-size: 17px;
    font-weight: 500;
  }

  p {
    font-size: 13px;
  }
}

.tableLength {
  font-size: 13px;
  font-weight: 500;
  margin: 20px 0 20px 0;
  text-align: right;
}
.products-page {
  .tableLength {
    font-size: 13px;
    font-weight: 500;
    margin: 0rem;
    float: left;
    clear: both;
  }
}

.editModalBody {
  p {
    font-weight: bold;
    font-size: 14px;
  }
}

.acceptSubmitDiv {
  text-align: center;
  margin: auto;
}

.editModalBody {
  span {
    display: inline-block;
    word-break: break-all;
    max-width: 300px;
  }
}







//products page
.product-image {
  width: 100%;
}

.integrationBlock {
  display: inline-block;
  padding: 0rem 0.5rem;
  font-size: 16px;
  line-height: 10px;
&::after{
content: ",";
position: absolute;
}
&:last-child::after{
  content: "";
}
}
.submitBtn{
  background: rgba(12, 127, 181, 0.8);
  color: #f1f1f1;
  text-transform: capitalize;
  border-style: none;
  cursor: pointer;
  outline: none;
  border: 2px solid rgba(12, 127, 181, 0.8);
  font-weight: 700;
  border-radius: 1.5rem;
  padding: 0.5rem 1.3rem;

  &:hover {
    background: rgb(12, 127, 181);
    color: #f1f1f1;
    text-transform: capitalize;
    border-style: none;
    cursor: pointer;
    outline: none;
    border: 2px solid rgb(12, 127, 181);
    font-weight: 700;
  }

}
.btn-danger{
  text-transform: capitalize;
  border-style: none;
  cursor: pointer;
  outline: none;
  border: 2px solid #f86c6b;
  font-weight: 700;
  border-radius: 1.5rem;
  padding: 0.5rem 1.3rem;
  &:hover {
    background: #f86c6b;
    color: #f1f1f1;
    text-transform: capitalize;
    border-style: none;
    cursor: pointer;
    outline: none;
    border: 2px solid #f86c6b;
    font-weight: 700;
  }
}
.btn-info.disabled, .btn-info:disabled{
  background-color: #cccccc;
  color: #f1f1f1;
  cursor: not-allowed;
  border: none;
}
.productsTable {
  margin-top: 1rem;
}
table.productsTable tr:hover {
  cursor: pointer;
}

//users page
table.usersTable tr:hover {
  cursor: pointer;
}

.usersLabel {
  width: 100%;
  text-align: right;

  @include ar {
    text-align: left;
  }
}

.usersModal .form-group>label.switch {
  position: relative;
  vertical-align: middle;
}

.infoItem {
  margin: 10px 0 20px 0;
}

.currentApiKey,
.hmac {
  word-break: break-all;
}

.infoItem {
  i {
    @include en {
      margin-right: 5px;
    }

    @include ar {
      margin-left: 5px;
    }
  }

  .accept-btn {
    i {
      @include en {
        margin-right: 0;
      }

      @include ar {
        margin-left: 0;
      }
    }
  }
}

.settingsBoxes {
  display: block;
  width: 100%;
  padding: 15px;
  overflow: hidden;
  height: 160px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: pointer;
  color: #73879C;
  text-decoration: none;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 8px #ccc;
    text-decoration: none;
    color: #167495 !important;
  }

  .icon {
    height: 28px;
    margin-bottom: 10px;
  }
}

.tableBreak {
  display: inline-block;
  word-break: break-all;
  max-width: 160px;
}

.orderExportBtn {
  margin: auto;
}

.orderExportConfirm {
  text-align: center;
}

.inputDiv {
  margin-bottom: 20px;
}

.modalTitle {
  display: flex;
  line-height: 40px;

  span {
    line-height: 20px;
    margin-right: 5px;
  }
}

.mailHint {
  margin-bottom: 20px;

  p {
    display: block;

    i {
      margin: 0 5px 0 10px;
    }
  }
}


// OrdersPage
.headerInput {
  position: absolute;
  top: 5px;
  right: 1rem;
  border-radius: 8px;
  height: 25px;
  width: 80px;
}

.updateRange {
  position: absolute;
  right: 0;
  bottom: 2px;
}

.rs-picker-daterange-menu .rs-calendar-month-dropdown {
  z-index: 9999 !important;
}

.modalHeader {
  display: inline-block;
}

//transfers 
.transfersTable tr:hover {
  cursor: pointer;
}
.transfersTable{
  overflow-x: auto;
}
.currentApiKey {
  width: 100%;
  border: 0;
  word-break: break-all;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  min-height: 100px;
}

.NoDataAvail {
  font-size: 30px;
  text-align: center;
  margin-top: auto;
}

//profile page




.navbarLogo {
  height: 35px;
  width: 35px;
  margin: 0px 5px;
  border-radius: 100%;
}

.app-header .nav-item .nav-link {
  height: 57px;
}

.Header_Toggle {
  height: 57px;
  border: 0;
  background-color: transparent;
  font-size: 26px;
  color: #5A738E;
  cursor: pointer;
  margin: 0 5px;
  outline: none !important;
}

.side-text {
  @include en {
    text-align: left;
  }

  @include ar {
    text-align: right;
  }
}

.updateProfileRender .form-group {
  margin-bottom: 5px !important;
}

input.profile-update-input:disabled {
  border: 0px;
  color: #999;
  padding: 4px 0px;
}

.updateProfileRender .inputGroup .simple-alert>i {
  top: 7px;
}

.inlineDiv {
  display: inline-block;
  margin: 0px !important;
}

.updateProfileRender .modal-footer button {
  margin: 0px 3px !important;
}

.app-header .header_drop_out .dropdown-item {
  padding: 10px 15px;
}

.app-header .header_drop_out .dropdown-item i {
  @include en {
    padding: 3px 0px;
    margin-right: .3em;
    margin-left: 0px;
  }
}

.removeEleBtn {
  position: absolute;
  top: 3px;
  font-size: 18px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  @include en {
    right: 24px;
  }

  @include ar {
    left: 24px;
  }
}

.removeEleBtn:hover {
  transform: scale(1.1, 1.1)
}

.add-btn {
  margin: 0px;
}

.addBrnanch {
  margin-bottom: 15px;
}

.closeIcone {
  position: absolute;
  font-size: 16px;
  top: -3px;
  z-index: 99;
  cursor: pointer;

  @include en {
    right: 15px;
  }

  @include ar {
    left: 15px;
  }
}

.merchantsTableHeader {
  margin-bottom: 5px;

  @include en {
    text-align: right;
  }

  @include ar {
    text-align: left;
  }
}


.r2bResp {
  text-align: center;
  font-size: 24px;
  margin: auto;
}

.refundLabels {
  display: flex;
  flex-direction: column;
}

.refund_product {
  padding: 20px 0;
  text-align: center;
}

.refund_product ul {
  padding: 0;
  margin: auto;
}

.refund_product li {
  font-family: Segoe Bold;
  list-style: none;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  color:rgba(12, 127, 181, 0.9);
  border: 2px solid rgba(12, 127, 181, 0.9);
  margin: 10px 0 0 5px;
  background-color: #FFF;
}
.refund_product li:hover {
  cursor: pointer;
}
// .refund_product li:hover,
.refund_product li.active {
  color:#FFF;
  background-color: rgba(12, 127, 181, 0.9);
}
.refund_product p {
  margin: auto;
}

//----------------- pse -------------------
.sidebar-pse {
  background-color: #47c0bb;
}

.sidebar-pse .nav-title {
  background-color: #47c0bb;
}

.sidebar-pse .sideMenuFooter {
  background-color: #47c0bb;
}

.sidebar-pse .sideMenuFooter>div {
  background-color: #47c0bb;
}

.sidebar-pse .navbar-brand img:first-of-type {
  width: auto;
  padding: 0px 15px;
}
.sidebar-pse .navbar-brand img:first-of-type{
    width: auto;
    padding: 0px 15px;
}

//------------------------------------------ Bank Layout ---------------------------------------
//----------------------- switch to checkbox
.bankLayout .switch-pill .switch-slider{
    width: 26px;
    height: 26px;
    border-radius: 3px;
}
.bankLayout .switch-slider::before{
    display: none;
}
.bankLayout .switch-input:checked ~ .switch-slider::before{
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
}

// Invoice page
.addInvoiceModal{
  .modal-content{
    min-width: 700px;
  }
  .x_panel{
    border: none;
  }
}

.hidden{
  width: 0;
  height: 0;
  z-index: -1;
  background: transparent;
  border: 0;
}
.copyBtn{
  background: #fff;
  position: relative;
  border: 1px solid #ccc;
  outline: 0;
  cursor: pointer;
  margin: 0 20px;
}
.copied{
  width: 70px;
    background-color: #EDEDED;
    font-size: 12px;
    position: relative;
    margin: 10px;
}
.popup-conatiner{
  background: #F7F7F7;
  height: 100%;

.Popup-modal{
  width: 100%;
  background: #fff;
  padding: 2rem;
  box-shadow: 5px 5px 5px 22px #f5f5f5;
  margin: 2rem auto;
  border-radius: 1rem;
  z-index: 1000;
  left: 0;
  right: 0;
  display: flex;
  .popup-body{
    margin: auto;
  }
}
}
@media only screen and (min-width: 992px) {
  .Popup-modal{
    left: 2%; 
    right: 2%;
    width: 60% !important;
    .modal-content{
      border-radius: 1rem;
      width: 100%;
    }
  }
}
.popup-route{
.modal-backdrop{
  background: #F7F7F7 !important;
  }
  .modal-backdrop.show{
    opacity: 1 !important;
  }
}
.accept_side_logo{
  @media (max-width: 991px) {
    width: 100%;
  }
}
.accept_side_logo img:first-child{
  padding: 10px;
}
.minViewBtn{
  @media (max-width: 991px) {
    position: absolute;
    @include en{
      left: 10px;
    }
    @include ar{
      right: 10px;
    }
  }
}
.filters-body.dropdown-menu.show{
  @include en{
    right: 0px !important;
    left: auto !important;
    direction: ltr;
  }
  @include ar{
    left: 0px !important;
    right: auto !important;
    direction: rtl;
  }
}
.modal.fade.show{
  opacity: 1 !important;
}

.sidebar .nav-item.nav-dropdown.open > ul li a.documentationUrl.active{
  color: #2F4D6A;
  &:hover{
    color: #0180FD;
    .nav-icon{
      color: #0180FD;
    }
  }
}
.sidebar .nav-link.documentationUrl.active .nav-icon{
  color: #2F4D6A;
}
html[dir="ltr"] .sidebar .documentationUrl.nav-link.active{
  border-right: 0px solid #0180FD !important;
  &:hover{
    border-right: 5px solid #0180FD !important;
  }
}
html[dir="rtl"] .sidebar .documentationUrl.nav-link.active{
  border-left: 0px solid #0180FD !important;
  &:hover{
    border-left: 5px solid #0180FD !important;
  }
}

.accept-btn.disabled, .accept-btn:disabled{
  cursor: not-allowed;
  &:hover{
    cursor: not-allowed;
  }
}


.paginationList.noCountPagination > li{
  display: none;
}
.paginationList.noCountPagination > li.next,
.paginationList.noCountPagination > li.selected,
.paginationList.noCountPagination > li.previous{
  display: inline-block;
}

.complate_onboarding_div{
  text-align: center;
  padding-top: 30px;
  padding-bottom: 50px;
  p{
    font-size: 18px;
    color: #333;
    margin-bottom: 30px;
  }
}

td.NEXT_uuid_td{
  overflow: initial !important;
}
.NEXT_uuid{
  position: relative;
  span{
    display: none;
    position: absolute;
    top: -25px;
    color: #fff;
    background-color: rgba(0, 0, 0, .8);
    border-radius: 7px;
    padding: 2px 7px;
  }
}
.NEXT_uuid:hover > span{
  display: block;
}


.UIG_integration{
  padding: 0.2rem 0.4rem !important;
  height: 100% !important;
  font-size: 16px !important;
  

}
.invoice_container{
  margin-bottom: 25px;background: #fff;
}
.Toastify__toast-container{
  @media (min-width: 991px) {
    width: auto !important;
  }
  width: 100% !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error,
.Toastify__toast-theme--colored.Toastify__toast--warning{
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.primaryBtn{
    float: none;
    display: inline-block;
    height: 35px;
    min-width: 105px;
    text-align: center;
    background: #0180FD;
    border: 1px solid transparent !important;
    border-radius: 3px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 35px;
    padding: 0px 15px;
    text-decoration: none !important;
    transition: all ease-in-out .3s;
    &:hover{
        cursor: pointer;
        background-color: transparent;
        border: 1px solid #1782FB;
        color: #0180FD;
    }
    &:focus{
        outline: none;
        box-shadow: none;
    }
    &:disabled{
        color: #fff;
        background: #0180FD;
        opacity: 0.7;
        cursor: not-allowed;
    }
    @include m(767){
        padding: 0px 5px;  
        min-width: auto;

    }
}
.activate_onboarding_btn{
  vertical-align: middle;
  float: none;
  display: inline-block;
  height: 35px;
  min-width: 105px;
  text-align: center;
  background: #0180FD;
  border: 1px solid transparent !important;
  border-radius: 3px !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 35px;
  padding: 0px 15px;
  text-decoration: none !important;
  transition: all ease-in-out .3s;
  @include en{
    margin-right: 5px;
  }
  @include ar{
    margin-left: 5px;
  }
  @include m(767){
    padding: 0px 5px;
    margin: 0 !important;
    min-width: auto;
    span{
      display: none;
    }
  }
  &:hover{
    cursor: pointer;
    background: #0180FD !important;
    border: 1px solid #1782FB !important;
    color: #FFF !important;
  }
  &:focus{
    outline: none;
    box-shadow: none;
  }
  &:disabled{
    color: #fff;
    background: #0180FD;
    opacity: 0.7;
    cursor: not-allowed;
  }
  img{
    width: 18px;
    @include en{
      margin-right: 5px;
    }
    @include ar{
      margin-left: 5px;
    }
    @include m(767){
      margin: 0 !important;
    }
  } 
}
.main-select {
  font-family: "Inter" !important;
  margin-bottom: 24px;
  .label {
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $TextColorGray;
    margin-bottom: 8px;
  }

  .MuiInputBase-root {
    .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      border: 1px solid $borderColor;

      &:focus {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #b9dafe;
      }
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #b9dafe;
  }

  .MuiInputBase-root:hover {
    border-color: $borderColor;
  }

  .MuiFormControl-root:focus {
    .MuiInputBase-root {
      outline: 0;
      border: none;
    }
  }
  .rsPicker {
    width: 100% !important;
    font-family: $FontFamily;
    font-weight: 400;
    border-radius: 8px;
    background: $CardBackground;
    gap: 8px;
    line-height: 16px;
    cursor: pointer;
  
  }
}
.rs-picker-toolbar-ranges {
  margin-bottom: 12px !important;
  display: flex !important;
  overflow-x: auto;
  padding-bottom: 8px;
}

.rs-picker-toolbar-ranges::-webkit-scrollbar {
  width: 2px !important; 
}
.rs-picker-toolbar-ranges::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
.rs-picker-toolbar-ranges::-webkit-scrollbar-thumb {
  background-color: #ccc; 
  border-radius: 4px; 
}
.rs-picker-toolbar-ranges::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
.rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
  max-width: 282px !important;
  margin: auto !important;
}
.reportsTimeRange{
  width: max-content
}
.main-date .rs-stack-item > svg {
  // top: 13px !important;
  position: absolute;
  top: 50% !important;
  transform: translate(50%, -50%);
}

#cards-tooltip {
  font-size: 10px;
}

.filters-toggle {
  .switch {
    position: absolute;
    top: 0;

    @include en {
      right: 0;
    }

    @include ar {
      left: 0;
    }
  }

  .switch-slider {
    background-color: #bcccdc;
  }

  .switch-input {
    display: none;
  }
}
#instant-settlement-tooltip {
  z-index: 10000; // higher than ac__modal-wrapper class 
  font-size: 12px;
  font-weight: 600;

  span {
    font-size: 10px;
    font-weight: 500;
  }

  p {
    margin: 4px;
  }

  .tooltip-inner {
    padding: 8px;
    text-align: start;
  }
}