@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Roboto:wght@100;200;300;400;500;600;700&display=swap');
$mainColor: #0078fc;
$titleColor:#101828;
$basicBackground:#F9FAFB;
$titleFontSize: 24px;
$baseWidth:2880;
$baseWidth: 2880;
$boldTextColor: #475467;
$lightTextColor: #667085;
$successGreen: #ecfdf3;
$FontFamily:"Inter", sans-serif;
$CardBackground:#FFFFFF;
$TextColorGray:#344054;
$TestColor:#DC6803;
$lightBackgroundColor:#E8F3FF;
$BaseColorCode:#1268C9;
$textFontSize:14px;
$smallTextFontSize:11px;
$ChipCardBackground:#FEF3F2;
$SucesschipColor:#039855;
$ErrorChipColor:#FF1552;
$borderColor:#EAECF0;
$SecondBorderColor:#D0D5DD;
$newReportBorderColor: #1268C9;
$SuccessTransactionsColor: #04B389;
$SuccCompareColor: #B4E8DC;
$DangerCompareColor: #FFB9CB;

@mixin ar {
  html[dir="rtl"] & {
    @content;
  }
}
:export {
  mainColor: $mainColor;
  boldTextColor: $boldTextColor;
  lightTextColor: $lightTextColor;
  successGreen: $successGreen;
}

// Text variables
$font-family_md: 'Inter';
$font-style_md: normal;
$font-weight_md: 500;
$font-size_md: 16px;
$line-height_md: 24px;

.text_md{
  font-style: $font-size_md;
  font-weight: $font-weight_md;
  font-size: $font-size_md;
  line-height: $line-height_md;
}
