@font-face {
  font-family: 'Gotham-Book';
  src: local('Gotham-Book'), url(./../../../../assets/fonts/HCo_Gotham_1/Gotham-Book.otf) format('opentype');
}
@font-face {
  font-family: 'Gotham-Light';
  src: local('Gotham-Light'), url(./../../../../assets/fonts/HCo_Gotham_1/Gotham-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Gotham-Medium';
  src: local('Gotham-Medium'), url(./../../../../assets/fonts/HCo_Gotham_1/Gotham-Medium.otf) format('opentype');
}

@mixin m($width) {
  @media(max-width:#{$width}px) {
      @content;
  }
}
@mixin en {
  html[dir="ltr"] & {
    @content;
  }
}

@mixin ar {
  html[dir="rtl"] & {
    @content;
  }
}
.onboardingBtn{
  float: right;
}
.primaryBtn{
  display: inline-block;
  height: 35px;
  min-width: 105px;
  text-align: center;
  background: #0180FD;
  border: 1px solid transparent !important;
  border-radius: 3px !important;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  padding: 0px 15px;
  text-decoration: none !important;
  transition: all ease-in-out .3s;
  &:hover{
      cursor: pointer;
      background: #FFF;
      border: 1px solid #1782FB !important;
      color: #0180FD !important;
  }
  &:focus{
      outline: none;
      box-shadow: none;
  }
  &:disabled{
      color: #fff !important;
      background: #0180FD;
      opacity: 0.7;
      cursor: not-allowed;
  }
  @include m(767){
      padding: 0px 5px;
      font-size: 14px;
      min-width: auto;
  }
}
.successBtn{
  float: none;
  display: inline-block;
  height: 35px;
  min-width: 105px;
  text-align: center;
  background: #38E8CC;
  border: 1px solid transparent;
  border-radius: 3px !important;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  padding: 0px 15px;
  text-decoration: none !important;
  transition: all ease-in-out .3s;
  &:hover{
      cursor: pointer;
      background: #FFF;
      border: 1px solid #38E8CC !important;
      color: #38E8CC !important;
  }
  &:focus{
      outline: none;
      box-shadow: none;
  }
  @include m(767){
      padding: 0px 5px;
      font-size: 14px;
      min-width: auto;
  }
}
.secondryBtn{
  float: none;
  display: inline-block;
  height: 35px;
  min-width: 105px;
  text-align: center;
  background: #61DEFF;
  border: 1px solid transparent;
  border-radius: 3px !important;
  color: #fff;
  font-size: 16px;
  line-height: 35px;
  padding: 0px 15px;
  text-decoration: none !important;
  transition: all ease-in-out .3s;
  &:hover{
      cursor: pointer;
      background: #FFF;
      border: 1px solid #61DEFF !important;
      color: #61DEFF !important;
  }
  &:focus{
      outline: none;
      box-shadow: none;
  }
  @include m(767){
      padding: 0px 5px;
      font-size: 14px;
      min-width: auto;
  }
}

.Popup{
  width: 100%;
  padding: 2rem;
  margin: 4vh auto;
  border-radius: 1rem;
  position: absolute;
  z-index: 99;
  left: 0;
  right: 0;
  display: block; 
}
.popup-conatiner{
  background: #F7F7F7;
  height: auto;
  .bg-img img{
    padding-bottom: 2vh;
    display: flex;
    margin-bottom: 4vh;
    padding-top: 2vh;
    margin-left: auto;
    margin-right: auto;
  }
  .popup-body{
    padding: 2rem;
    position: relative;
    display: block;
    background: #F7F7F7;
    }
  .Popup-modal{
    width: calc(100% - 30px) !important;
    margin-right: 15px;
    background: #fff;
    padding: 25px;
    padding-bottom: 15px;
    box-shadow: 0px 3px 6px #0000001A;
    border-radius: 10px;
    margin: 4vh auto;
    // position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  ol.progtrckr{
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .Popup-modal{
    left: 2%; 
    right: 2%;
    .modal-content{
      border-radius: 1rem;
      width: 100%;
    }
    ol.progtrckr{
      text-align: center;
      position: absolute;
      top: -6rem;
      padding: 2rem 0rem;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
      display: block;
    }
    ol.progtrckr li.progtrckr-done:before{
      content: "";
      background-color:#28AAE1;
    }
    ol.progtrckr li{
      padding: 2px 8vw;
     span{
      display: none;
    }
  }
  ol.progtrckr li.progtrckr-done{
    border-bottom: 5px solid #28AAE1;
  }
  ol.progtrckr li.progtrckr-doing{
    border-bottom: 5px solid #B3BBC4;
  }
  ol.progtrckr li.progtrckr-doing:before{
    content: "";
    background-color: #B3BBC4;
  }
  ol.progtrckr li.progtrckr-todo{
    border-bottom: 5px solid #B3BBC4;
  }
  ol.progtrckr li.progtrckr-todo:before{
    content: "";
    background-color: #B3BBC4;
  }
  ol.progtrckr li:after{
    content: "";
  }

  }
}
.popup-route{
.modal-backdrop{
  background: #F7F7F7 !important;
  }
  .modal-backdrop.show{
    opacity: 1 !important;
  }
}
.businessHeader{
 font-weight: bolder;
}
legend{
 font-weight: 700;
 color: #73879C !important;
 text-align: center;
 font-weight: bold;
 margin-bottom: 1rem;
 font: Bold 24px/32px Segoe UI;
}
.steptwo-form{
  padding: 1rem;
}

#next-button{
padding: 0.5rem 2rem;
font-weight: 700;
 border-radius: 10px;
 background: #0080FD 0% 0% no-repeat padding-box;
 margin-top: 1rem;
 font-size: 17px;
  color:#fff ;
}

#prev-button{
  margin-top: 1rem;
  border: none;
  font-weight: 700;
  background: none;
  color: #73879C;
  position: absolute;
  left: 46rem;
  padding: 0.5rem 1rem;
  font-size: 17px;
 @include ar{
  float: right;
  right: 56%;

 }
}
@media only screen and (min-width: 992px) {
  #prev-button{
    // left: 40vw;
    @include ar{
      float: right;
      right: 60%;
     }
}
}
.steptwo-form{
  margin: 0px;
  margin-top: 15px;
  padding: 0px;
  border: 2px solid #E7E9ED;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 22px;
 @include ar{
  padding-right: 2rem;
 }
 &:hover{
  cursor: pointer;
    // border: 3px solid #E7E9ED;
  // .form-check-label{
  //   color: #2469A4;
  //   cursor: pointer;
  // }
}
.activation{
  display: table;
  font-size: 22px;
  
span{
  color: #B3BBC4;
  clear: both;
  display: block;
  font-size: 16px;
}
.smallHint{
  display: inline;
  color: #31D097;
  font-size: 14px;
  padding: 1rem;
}
.form-check-label{
  color: #0080FD;
  cursor: pointer;
}
h5{
  display: inline-block;
  color: #0080FD;
  cursor: pointer;
  font-weight: bold;
}
}
.form-check-label{
  width: 100%;
  // padding-left: 1rem;
  // padding-right: 1rem;
  padding: 15px 30px;
  color: #73879C;
  font-size: 22px;
 
  &:hover{
    cursor: pointer;
  }
  @include ar{
    padding-right: 1rem;
  }
}
.options-content{
  display: table;
  font-size: 22px;
  margin-left: 85px;
  @include ar{
    margin-left: 0px;
    margin-right: 85px;
  }
  h5{
    display: inline-block;
  }
}
.icon-container{
float: left;
@include ar{
  float: right;
}
.popupIcon{
  width: 40px;
  height: 40px;
  margin: auto 1rem;
}
.form-check-input{
  margin-left: -0.8rem;
  margin-top: 0.8rem;
  cursor: pointer;
  @include ar{    
  margin-right: -0.8rem;
  margin-left: 0rem;
  }
  
}
}
.steptwo-legand{
  padding-left: 0rem;
}
.footer-buttons{
  margin-top: 1rem;
}
}

.stepOne-container{
  margin: 0px;
  margin-top: 15px;
  padding: 0px;
  border: 1px solid #E7E9ED;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 5px;
  transition: all ease-in-out .5s;
  &:hover{
    cursor: pointer;
    box-shadow: 0px 3px 6px #0000001A;
  }
  // @include ar{
  //   margin-right: 1.5rem;
  //   margin-left: 0rem;
  //   direction: rtl !important;
  //   border: 1px solid #E7E9ED;
  // }
    // border: 3px solid #E7E9ED;
    .activation{
      display: table;
      font-size: 22px;
      margin-left: 85px;
      // @include ar{
      //   margin-left: 0px;
      //   margin-right: 85px;
      // }
      &:hover{
        cursor: pointer;
      }
    span{
      color: #B3BBC4;
      clear: both;
      display: block;
      font-size: 16px;
    }
    .smallHint{
      display: inline;
      color: #31D097;
      font-size: 14px;
      padding: 1rem;
    }
    .form-check-label{
      color: #0080FD;
      cursor: pointer;
    }
    h5{
      display: inline-block;
      color: #0080FD;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .form-check-label{
    position: relative;
    display: block;
    // padding-left: 1rem;
    // padding-right: 1rem;
    padding: 15px 30px;
    color: #73879C;
    font-size: 22px;
    &:hover{
      cursor: pointer;
    }
    // @include ar{
    //   padding-right: 1rem;
    // }
  }
  .options-content{
    display: table;
    font-size: 22px;
    margin-left: 85px;
    // @include ar{
    //   margin-left: 0px;
    //   margin-right: 85px;
    // }
    &:hover{
      cursor: pointer;
    }
  span{
    color: #B3BBC4;
    clear: both;
    display: block;
    font-size: 16px;
  }
  .smallHint{
    display: inline;
    color: #31D097;
    font-size: 14px;
    padding: 1rem;
  }
  h5{
    display: inline-block;
  }
}
.icon-container{
  position: absolute;
  float: right;
  top: 50%;
  transform: translateY(-50%);
  // @include ar(){
  //   float: right;
  // }
  &:hover{
    cursor: pointer;
  }
  .popupIcon{
    width: 50px;
    height: 50px;
    margin: auto 1rem;
  }
  .form-check-input{
    margin-top: 20px;
    margin-left: -15px;
    cursor: pointer;
    // @include ar{
    // margin-right: -15px;
    // margin-left: 0px;
    // }
}
}
}
.stepone-hint{
  // margin-left: 3rem;
  color: #73879C;
  font-size: 16px;
  i{
    padding: 0.2rem 0.5rem;
    border-radius: 50%;
    border: 1px solid #FF0100;
    color: #FF0100;
    margin-right: 0.4rem;
  }
}
.Business-info-container{
  .brand-name-container{
    font-size: 14px;
  }
.businessInfo-legand{
color: #73879C;
font-weight: bold;
}
p{
color: #73879C;
font-size: 18px;
&:hover{
color:#0080FD ;
cursor: pointer;
}
 }
  .industry-container{
    margin-top: 1rem;
    font-size: 14px;
    letter-spacing: 0.35px;
    color: #B3BBC4;
    opacity: 1;
  }
.business-textarea{
  height: 125px !important;
  text-align: left;
  font-size: 14px;
  letter-spacing: 0.35px;
  color: #495057;
  opacity: 1;
}
.webIcon{
  width: 40px;
}
.webIcon i{
  margin: auto;
  font-size: 17px !important;
}
.address-container{
      margin-top: 1.3rem;
      font-size: 14px;
  letter-spacing: 0.35px;
  color: #B3BBC4;
  opacity: 1;
}
.address{
  margin-bottom: 1rem;
}
.input-group-text {
  background: #F2F4F6 0% 0% no-repeat padding-box;
  color: #B3BBC4;
img{
  width: 15px;
  height: 15px;
  margin-right: 1rem;
 }
}
 #next-button:disabled{
   cursor: no-drop !important;
 }
.stepper_submit{
      padding: 0.5rem 2rem;
      font-weight: 700;
      float: right;
      border-radius: 10px;
      margin-top: 1rem;
      background-color: #0080FD;
      font: Semibold 17px/27px Segoe UI;
      color:#fff ;
      cursor: pointer;
      // @include ar{
      //   float: left;
      // }
    }
.skip-btn{
      margin-top: 10px;
      border: none;
      font-weight: 700;
      background: none;
      color: #D9E0E8;
      // position: absolute;
      float: left;
      padding: 0.5rem 1rem;
      outline: none;
      cursor: pointer;
      font-size: 15px;
      &:hover{
            border: none;
            outline: none;
      }
}
}
.ErrorMessage{
  color: #FF0100;
  font-size: 15px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  position: relative;
}
.Popup-modal{
select{
  background:
    linear-gradient(45deg, transparent 50%, #B3BBC4 50%),
    linear-gradient(135deg,#B3BBC4 50%, transparent 50%),
    linear-gradient(to right,#fff, #fff);
  background-position:
    calc(100% - 21px) calc(1em + 2px),
    calc(100% - 16px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance:none;
  -moz-appearance:none;
}
}
.sucess-container{
  text-align: center;
  i{
    font-size: 50px !important;
    margin-bottom: 10px !important;
    text-align: center;
    margin: auto;
    color: #28AAE1;
    opacity: 1;
  }
  .sucess-content{
  h1{
    text-align: center;
     letter-spacing: 0.6px;
     font-size:  24px;
     color: #73879C;
  }
  p{
    text-align: center;
font-size: 16px;
letter-spacing: 0.4px;
color: #909EAE;
  }
}
}

.businessDataPopup{
  height: 100vh;
  overflow: auto;
  display: block;
}
.businessDataPopup *{
  font-family: 'Gotham-Light';
}
.businessDataPopup i.fa{
  font: normal normal normal 14px/1 FontAwesome;
}
.businessDataPopup .logo_link{
  margin: auto;
  display: block;
  margin-top: 110px;
}
.businessDataPopup .logo_link img{
  width: 200px;
  margin-bottom: 0px;
}

.stepFieldset{
  margin: 0px !important;
}
.stepBtnDiv{
  height: 34px;
  margin-top: 15px;
}
.stepBtnDiv .nextBtn{
  background: #0080FD 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  outline: transparent !important;
  color: #fff;
  padding: 4px 15px;
  float: right;
  transition: all ease-in-out .5s;
  &:hover{
    box-shadow: 0px 3px 6px #0000001A;
    cursor: pointer;
    transform: translateY(-1px);
  }
  &:disabled{
    cursor: not-allowed;
    opacity: .3;
  }
}
.stepBtnDiv .backBtn{
  background: transparent !important;
  outline: transparent !important;
  border: none;
  border-radius: 6px;
  color:#666;
  padding: 4px 15px;
  float: right;
  transition: all ease-in-out .5s;
  &:hover{
    cursor: pointer;
    color: #0080FD;
  }
  &:disabled{
    cursor: not-allowed;
    opacity: .6;
    color:#B3BBC4 !important;
  }
}
.Ar{
  direction: rtl !important;
  .stepOne-container{
    .icon-container{
      float: left;
      .form-check-input{
        margin-right: -15px;
      }
    }
    .options-content{
      margin-right: 85px;
      h5{
        float: right;
      }
    }
    .activation{
      margin-right: 85px;
      h5{
        float: right;
      }
    }
  }
  .stepone-hint{
    display: flex;
  }
  .hint-step2{
    padding-bottom: 1rem;
  }
  .steptwo-form{
    .icon-container{
float: right;
.form-check-input{
  margin-right: -15px;
}
}
.options-content{
margin-right: 85px;
h5{
  float: right;
}
}
.activation{
margin-right: 85px;
h5{
  float: right;
}
}
  }
  .stepBtnDiv{
float: left;
.nextBtn{
  float: left;
}
.backBtn{
  float: left;
}
  }
.Business-info-container{
  .brand-name-container ,.industry-container,.address-container{
  p{
    display: flex;
  }
}
.business-textarea{
  text-align: right;
}
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group>.input-group-append:last-child>.input-group-text:not(:last-child), .input-group>.input-group-append:not(:last-child)>.btn, .input-group>.input-group-append:not(:last-child)>.input-group-text, .input-group>.input-group-prepend>.btn, .input-group>.input-group-prepend>.input-group-text{
 direction: rtl;
 border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.input-group-prepend{
  margin-left: -1px;
  margin-right: 0px;
}
.input-group-text {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
 img{
  margin-left: 1rem;
  margin-right:0rem;
 }
}
.skip-btn{
  float: right;
}
}
.Popup-modal select{
  background:none;
}
}