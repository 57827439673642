.copyBtn {
    border: none;
    background: #fff;
    width: auto; 
    height: 31px;
    display: inline-flex; 
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    margin: 0 10px;
    color: #1782FB;
    font-size: 16px;
    box-shadow: 0px 0px 6px #1782FB3B;
    transition: all ease-in-out 0.3s;
  }
  
  .copyBtn .fa-check,
  .copyBtn .fa-clone {
    color: #1782FB;
    font-size: 17px;
    margin-left: 5px; 
  }
  
  .copyBtn:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
  }
  
  .copyBtn:focus {
    outline: none;
    border: none;
  }
  